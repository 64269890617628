import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Box, Button, Heading } from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useTransition, animated } from "@react-spring/web";
import { NewsActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { INewsStructure } from "@/Interfaces/News.interface";

const { fetchNews } = NewsActions;

const NewsContent = ({
  title,
  content,
  onToggleExpand,
}: {
  title: string;
  content: string;
  onToggleExpand: (isFullScreen: boolean) => void;
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isContentOverflow, setIsContentOverflow] = useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      const contentElement = contentRef.current;
      if (contentElement) {
        setIsContentOverflow(
          contentElement.scrollHeight > contentElement.clientHeight
        );
      }
    };
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [content]);

  const handleToggleExpand = () => {
    const newState = !isFullScreen;
    setIsFullScreen(newState);
    onToggleExpand(newState);
  };

  return (
    <Box
      position="relative"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
      bg="white"
      p={6}
    >
      <Box
        ref={contentRef}
        style={{
          maxHeight: isFullScreen ? "none" : "300px",
          overflowY: "hidden",
          transition: "max-height 0.3s ease",
        }}
      >
        <Heading as="h4" size="md" mb={2} color="gray.700">
          {title}
        </Heading>
        <Box dangerouslySetInnerHTML={{ __html: content }} />
      </Box>
      {isContentOverflow && (
        <Box
          display="flex"
          justifyContent="center"
          fontSize="17px"
          fontWeight="bold"
          color="#B57357"
          cursor="pointer"
          marginTop={4}
          onClick={handleToggleExpand}
        >
          {isFullScreen ? "Collapse" : "Expand"}
          {isFullScreen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Box>
      )}
    </Box>
  );
};

const News: React.FC = () => {
  const dispatch = useTypedDispatch();
  const newsList: INewsStructure[] = useSelector((state: RootState) =>
    _.get(state.NEWS, "newsList")
  );

  // Lọc danh sách chỉ lấy các mục có status là "active"
  const activeNewsList = useMemo(() => {
    return newsList.filter((news) => news.status === "active");
  }, [newsList]);

  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const slideTimerRef = React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    dispatch(fetchNews());
  }, [dispatch]);

  const transitions = useTransition(currentNewsIndex, {
    from: { opacity: 0, transform: "translateX(10px)" },
    enter: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 500 },
  });

  const resetSlideTimer = () => {
    if (slideTimerRef.current) clearInterval(slideTimerRef.current);
    if (!isPaused && activeNewsList.length > 0) {
      slideTimerRef.current = setInterval(() => {
        setCurrentNewsIndex((prev) => (prev + 1) % activeNewsList.length);
      }, 15000);
    }
  };

  useEffect(() => {
    resetSlideTimer();
    return () => clearInterval(slideTimerRef.current!);
  }, [isPaused, activeNewsList]);

  const handleManualChange = (direction: "prev" | "next") => {
    resetSlideTimer();
    setCurrentNewsIndex((prev) =>
      direction === "next"
        ? (prev + 1) % activeNewsList.length
        : (prev - 1 + activeNewsList.length) % activeNewsList.length
    );
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Heading size="md" mb={4} color="#5E6E6C" fontWeight="bold">
          📰 Tin tức - Thông báo
        </Heading>
        <Box display="flex" gap={2}>
          <Button
            size="sm"
            leftIcon={<ChevronLeftIcon />}
            onClick={() => handleManualChange("prev")}
          >
            Prev
          </Button>
          <Button
            size="sm"
            rightIcon={<ChevronRightIcon />}
            onClick={() => handleManualChange("next")}
          >
            Next
          </Button>
        </Box>
      </Box>

      {transitions((style: any, index: number) =>
        activeNewsList[index] ? (
          <animated.div
            style={style}
            key={`${index}-${activeNewsList[index].id}`}
          >
            <NewsContent
              title={activeNewsList[index]?.title || ""}
              content={activeNewsList[index]?.content || ""}
              onToggleExpand={(isFullScreen) => setIsPaused(isFullScreen)}
            />
          </animated.div>
        ) : null
      )}
    </Box>
  );
};

export default News;
