import UserDataTable from "./UserDataTable";
import ProjectDataTable from "./ProjectDataTable";
import PerformanceDataTable from "./PerformanceDataTable";
import TimeOffRequestDataTable from "./TimeOffRequestDataTable";
import OvertimeDataTable from "./Overtime";
import LeaveDaysDataTable from "./LeaveDaysDataTable";
import UpdateRequestTimeCardDataTable from "./RequestUpdateTimeCard.Table";
import AggregateOvertimeDataTableDayOff from "./AggregareDataTableDayoff";
import AggregateDataTableOvertime from "./AggregateDataTableOvertime";
import HolidayDataTable from "./HolidayDataTable";
import InternalIPDataTable from "./InternalIPDataTable";
import ClientsDataTable from "./ClientsDataTable";
import DealineDashboardDataTable from "./DealineDashboardDataTable";
import WorkingTimeDataTable from "./WorkingTimeDataTable";
import ProjectExteriorDataTable from "./ProjectExteriorDataTable";
import BackupDataTable from "./BackupDataTable";
import TimeDeleteFilePrivateChatDataTable from "./TimeDeleteFilePrivateChat";
import NewsDataTable from "./NewsDataTable";

export default {
  UserDataTable,
  ProjectDataTable,
  PerformanceDataTable,
  TimeOffRequestDataTable,
  OvertimeDataTable,
  LeaveDaysDataTable,
  UpdateRequestTimeCardDataTable,
  AggregateDataTableOvertime,
  AggregateOvertimeDataTableDayOff,
  HolidayDataTable,
  InternalIPDataTable,
  ClientsDataTable,
  DealineDashboardDataTable,
  WorkingTimeDataTable,
  ProjectExteriorDataTable,
  BackupDataTable,
  TimeDeleteFilePrivateChatDataTable,
  NewsDataTable,
};
