import {
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
  Switch,
  Tooltip,
  Icon,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import CreateNewsDialog from "./CreateNewsDialog";
import UpdateNewsDialog from "./UpdateNewsDialog";
import { PencilSquareIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import NewsActions from "@/Actions/News.action";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { INewsStructure } from "@/Interfaces/News.interface";
import _ from "lodash";
import ConfirmDialog from "../Confirm";
import { useTranslation } from "react-multi-lang";

const { fetchNews, changeStatusNews } = NewsActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const NewsDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const [filterDialog, setFilterDialog] = useState<string>("LIST");
  const [confirmStatus, setConfirmStatus] = useState<{
    open: boolean;
    status: string;
    type: string;
    id: string;
  }>({ open: false, status: "", type: "", id: "" });

  const [NewsDetail, setNewsDetail] = useState<INewsStructure>({
    id: "",
    title: "",
    content: "",
    status: "",
  });

  const newsList: INewsStructure[] = useSelector((state: RootState) =>
    _.get(state.NEWS, "newsList")
  );

  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.NEWS, "isFetchLoading")
  );

  const isChangeStatusSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.NEWS, "isChangeStatusSuccess")
  );

  const onRowAction = (type: boolean, id: string) => {
    if (type)
      setConfirmStatus({
        open: true,
        status: "active",
        type: t("message.doYouWantToActiveNews"),
        id: id,
      });
    else
      setConfirmStatus({
        open: true,
        status: "inactive",
        type: t("message.doYouWantToInactiveNews"),
        id: id,
      });
  };

  const onChangeStatus = () => {
    if (confirmStatus?.id) {
      if (confirmStatus?.status === "inactive")
        dispatch(changeStatusNews(confirmStatus.id, { status: "inactive" }));
      else dispatch(changeStatusNews(confirmStatus.id, { status: "active" }));
    }
  };

  useEffect(() => {
    if (filterDialog === "LIST" && open) {
      dispatch(fetchNews());
    }
  }, [filterDialog, open]);

  useEffect(() => {
    if (!open) {
      onClose();
      setNewsDetail({
        id: "",
        title: "",
        content: "",
        status: "",
      });
    }
  }, [open]);

  useEffect(() => {
    if (isChangeStatusSuccess) {
      setConfirmStatus({ open: false, status: "", type: "", id: "" });
    }
  }, [isChangeStatusSuccess]);

  const _renderBodyNews = (value: string) => {
    switch (value) {
      case "LIST":
        return _renderNewsList();
      case "CREATE":
        return (
          <CreateNewsDialog
            onClose={() => setFilterDialog("LIST")}
            open={filterDialog === "CREATE"}
          />
        );
      case "UPDATE":
        return (
          <UpdateNewsDialog
            onClose={() => setFilterDialog("LIST")}
            open
            payload={NewsDetail}
          />
        );
      default:
        return "";
    }
  };

  const _renderNewsList = () => {
    return (
      <AlertDialog
        isOpen={open}
        leastDestructiveRef={cancelRef}
        finalFocusRef={cancelRef}
        onClose={onClose}
        isCentered
        size="sm"
        motionPreset="slideInBottom"
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            maxH: "full",
            borderRadius: 0,
          }}
        >
          <AlertDialogHeader
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            sx={{
              position: "sticky",
              zIndex: "sticky",
              top: 0,
              backgroundColor: "#fff",
              boxShadow: "0 2px 1px -1px gray",
            }}
          >
            <Text> News </Text>
            <Box
              sx={{ cursor: "pointer", position: "absolute", top: 1, right: 2 }}
              onClick={() => setFilterDialog("CREATE")}
            >
              <Tooltip label="Create News">
                <Icon
                  as={PlusCircleIcon}
                  boxSize={8}
                  sx={{ color: "#22c35e" }}
                />
              </Tooltip>
            </Box>
          </AlertDialogHeader>
          <AlertDialogBody
            sx={{
              overflowY: "auto",
              minH: 210,
            }}
          >
            {!isFetchLoading ? (
              <Box>
                {!_.isEmpty(newsList) ? (
                  <Box>
                    {_.map(newsList, (item: INewsStructure, index: number) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          key={index}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Text size={"sm"} sx={{ fontWeight: 600 }}>
                              News {index + 1}:
                            </Text>
                            <Text sx={{ ml: 2, fontWeight: 600 }}>
                              {item.title}
                            </Text>
                          </Box>
                          <Box>
                            <Tooltip label="Change status">
                              <Switch
                                isChecked={item?.status === "active"}
                                size={"sm"}
                                id="news"
                                onChange={(e) =>
                                  onRowAction(e.target.checked, item.id)
                                }
                              />
                            </Tooltip>
                            <Tooltip label="Edit" ml={1}>
                              <IconButton
                                sx={{
                                  bg: "transparent",
                                }}
                                size="md"
                                isRound
                                icon={<Icon as={PencilSquareIcon} />}
                                aria-label={""}
                                onClick={() => {
                                  setNewsDetail(item);
                                  setFilterDialog("UPDATE");
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box>{t("message.noDataWereFound")}</Box>
                )}
              </Box>
            ) : (
              <Box textAlign={"center"}>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Box>
            )}
          </AlertDialogBody>
          <AlertDialogFooter
            sx={{
              position: "sticky",
              bottom: 0,
              background: "#fff",
              boxShadow: "0px -1px 1px gray",
              justifyContent: "left",
            }}
          >
            <Button
              size={"sm"}
              ref={cancelRef}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  };

  const _renderMain = () => {
    return (
      <Box>
        {_renderBodyNews(filterDialog)}
        <ConfirmDialog
          isOpen={confirmStatus.open}
          onClose={() => {
            setConfirmStatus({ ...confirmStatus, open: false });
          }}
          body={confirmStatus.type}
          onAction={() => onChangeStatus()}
          actionType={confirmStatus.status === "active" ? "active" : "inactive"}
        />
      </Box>
    );
  };

  return <Box>{_renderMain()}</Box>;
};

export default NewsDialog;
