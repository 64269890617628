enum ROLES {
  ADMIN = "admin",
  LEADER = "leader",
  MANAGER = "manager",
  ARTIST = "artist",
  HUMAN_RESOURCES = "human resources",
  MARKETING = "marketing",
}

enum PROJECT_STATUS {
  WAITING_PROCESS = "waiting process",
  STARTING = "starting",
  IN_PROCESS = "in process",
  FINAL_DELIVER = "final deliver",
  HISTORY = "history",
}

enum PROJECT_TYPE {
  INTERIOR = "interior",
  EXTERIOR = "exterior",
}

enum USER_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

enum PROJECT_URGENCY {
  EARLY = "early",
  NORMALLY = "normally",
  NO_RUSH = "no rush",
  URGENT = "urgent",
}

type TColor = {
  text: string;
  background: string;
  lighterColor?: string;
  rgba?: string;
};

const URGENCY_COLOR: Record<PROJECT_URGENCY, TColor> = {
  [PROJECT_URGENCY.EARLY]: { text: "#ffffff", background: "#5c6e6c" }, // Updated background color
  [PROJECT_URGENCY.NORMALLY]: { text: "#ffffff", background: "#D2A96a" },
  [PROJECT_URGENCY.NO_RUSH]: { text: "#ffffff", background: "#a6b7aa" },
  [PROJECT_URGENCY.URGENT]: { text: "#ffffff", background: "#BB7154" },
};

const PROJECT_STATUS_COLOR: Record<
  PROJECT_STATUS,
  TColor & { lighterColor: string }
> = {
  [PROJECT_STATUS.WAITING_PROCESS]: {
    text: "#ffffff",
    background: "rgba(92, 110, 108, 1)",
    lighterColor: "rgba(92, 110, 108, 0.2)",
    rgba: "rgba(92, 110, 108, 0.6)",
  },
  [PROJECT_STATUS.STARTING]: {
    text: "#ffffff",
    background: "rgba(210, 169, 106,1)",
    lighterColor: "rgba(210, 169, 106,0.2)",
    rgba: "rgba(210, 169, 106,0.6)",
  },
  [PROJECT_STATUS.IN_PROCESS]: {
    text: "#ffffff",
    background: "rgba(211, 157, 135, 1)",
    lighterColor: "rgba(211, 157, 135, 0.2)",
    rgba: "rgba(211, 157, 135, 0.6)",
  },
  [PROJECT_STATUS.FINAL_DELIVER]: {
    text: "#ffffff",
    background: "rgba(187, 113, 84, 1)",
    lighterColor: "rgba(187, 113, 84, 0.2)",
    rgba: "rgba(187, 113, 84, 0.6)",
  },
  [PROJECT_STATUS.HISTORY]: {
    text: "#ffffff",
    background: "rgba(166, 183, 170, 1)",
    lighterColor: "rgba(166, 183, 170, 0.2)",
    rgba: "rgba(166, 183, 170, 0.6)",
  },
};

enum PROJECT_POSITION {
  LEADER = "leader",
  ARTIST = "artist",
  MANAGER = "manager",
}

enum LOG_TIME_DAY_TYPE {
  WEEKDAY = "weekday",
  WEEKEND = "weekend",
  HOLIDAY = "holiday",
}

enum LOG_TIME_WORK_TYPE {
  WORK_TIME = "work time",
  OVER_TIME = "over time",
}

enum LOG_TIME_TYPE {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

enum LOG_OVER_TIME_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

enum REQUEST_UPDATE_TIMECARD_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

enum DAY_OFF_REQUEST_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

enum HOLIDAY_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

enum PROJECT_EXTERIOR_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

enum PROJECT_URGENCY_STATUS {
  NORMALLY = "normally",
  EARLY = "early",
}

const LOG_OVER_TIME_STATUS_COLOR: Record<LOG_OVER_TIME_STATUS, TColor> = {
  [LOG_OVER_TIME_STATUS.APPROVED]: {
    text: "#ffffff",
    background: "#A6B7AA",
  },
  [LOG_OVER_TIME_STATUS.PENDING]: { text: "#ffffff", background: "#D2A96A" },
  [LOG_OVER_TIME_STATUS.REJECTED]: {
    text: "#ffffff",
    background: "#BB7154",
  },
};
enum CLIENT_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
enum NEWS_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

const TIMECARD_STATUS_COLOR: Record<DAY_OFF_REQUEST_STATUS, TColor> = {
  [DAY_OFF_REQUEST_STATUS.APPROVED]: {
    text: "#ffffff",
    background: "#5C6E6C",
  },
  [DAY_OFF_REQUEST_STATUS.PENDING]: { text: "#ffffff", background: "#D2A96A" },
  [DAY_OFF_REQUEST_STATUS.REJECTED]: {
    text: "#ffffff",
    background: "#BB7154",
  },
};

const TIMECARD_STATUS_DETAIL_COLOR: Record<DAY_OFF_REQUEST_STATUS, TColor> = {
  [DAY_OFF_REQUEST_STATUS.APPROVED]: {
    text: "#009E0F",
    background: "#FFFFFF",
  },
  [DAY_OFF_REQUEST_STATUS.PENDING]: { text: "#ffd700", background: "#FFFFFF" },
  [DAY_OFF_REQUEST_STATUS.REJECTED]: {
    text: "#FF0000",
    background: "#FFFFFF",
  },
};

const HOLIDAY_STATUS_COLOR: Record<HOLIDAY_STATUS, TColor> = {
  [HOLIDAY_STATUS.ACTIVE]: {
    text: "#ffffff",
    background: "#009E0F",
  },
  [HOLIDAY_STATUS.INACTIVE]: {
    text: "#ffffff",
    background: "#FF0000",
  },
};

enum NOTIFICATION_TYPE {
  USER = "user",
  DAY_OFF_REQUEST = "day off request",
  HOLIDAY = "holiday",
  INTERNAL_IP_ADDRESS = "internal ip address",
  NEWS = "news",
  KANBAN_BOARD = "kanban board",
  LEAVE_DAY = "leave day",
  LOG_TIME = "log time",
  OVERTIME = "overtime",
  PERFORMANCE_EVALUATION = "performance evaluation",
  PROJECT = "project",
  TASK = "task",
  TASK_COMMENT = "task comment",
  TIMEKEEPING = "timekeeping",
  TIMESHEET = "timesheet",
  REQUEST_UPDATE_TIMECARD = "request update timecard",
  CHAT = "chat",
  TASK_NOTE = "task note",
}

enum SOCKET_EVENT {
  MESSAGE_NOTIFICATION = "messageNotification",
  MESSAGE_CONVERSATION = "messageConversation",
  MESSAGE_CHANNEL = "messageChannel",
  MESSAGE_RECALLED = "messageRecalled",
  MESSAGE_CHANNEL_REMOVE_MEMBER = "messageChannelRemoveMember",
  MESSAGE_CHANNEL_ADD_MEMBER = "messageChannelAddMember",
  MESSAGE_CHANNEL_LEAVE_GROUP = "messageChannelLeave",
  MESSAGE_UPDATE_GROUP_AVATAR = "messageGroupUpdateAvatar",
  USER_CONNECT_SOCKET = "userConnectSocket",
  MESSAGE_GROUP_LEAVE_REMOVE = "messageGroupLeaveRemove",
  SAVE_TOKEN_FIREBASE = "saveTokenFirebase",
}

const PROJECT_EXTERIOR_STATUS_COLOR: Record<
  PROJECT_EXTERIOR_STATUS,
  TColor & { lighterColor: string }
> = {
  [PROJECT_EXTERIOR_STATUS.ACTIVE]: {
    text: "#ffffff",
    background: "rgba(187, 113, 84, 1)",
    lighterColor: "rgba(187, 113, 84, 0.2)",
    rgba: "rgba(187, 113, 84, 0.6)",
  },
  [PROJECT_EXTERIOR_STATUS.INACTIVE]: {
    text: "#ffffff",
    background: "rgba(166, 183, 170,1)",
    lighterColor: "rgba(166, 183, 170,0.2)",
    rgba: "rgba(166, 183, 170,0.6)",
  },
};

const PROJECT_EXTERIOR_URGENRY_COLOR: Record<
  PROJECT_URGENCY_STATUS,
  TColor & { lighterColor: string }
> = {
  [PROJECT_URGENCY_STATUS.NORMALLY]: {
    text: "#ffffff",
    background: "rgba(210, 169, 106,1)",
    lighterColor: "rgba(210, 169, 106, 0.2)",
    rgba: "rgba(210, 169, 106, 0.6)",
  },
  [PROJECT_URGENCY_STATUS.EARLY]: {
    text: "#ffffff",
    background: "rgba(211, 157, 135,1)",
    lighterColor: "rgba(211, 157, 135,0.2)",
    rgba: "rgba(211, 157, 135,0.6)",
  },
};

export {
  ROLES,
  PROJECT_STATUS,
  PROJECT_URGENCY,
  PROJECT_POSITION,
  URGENCY_COLOR,
  PROJECT_STATUS_COLOR,
  USER_STATUS,
  LOG_TIME_DAY_TYPE,
  LOG_TIME_WORK_TYPE,
  LOG_TIME_TYPE,
  LOG_OVER_TIME_STATUS,
  REQUEST_UPDATE_TIMECARD_STATUS,
  DAY_OFF_REQUEST_STATUS,
  TIMECARD_STATUS_COLOR,
  TIMECARD_STATUS_DETAIL_COLOR,
  HOLIDAY_STATUS_COLOR,
  NOTIFICATION_TYPE,
  SOCKET_EVENT,
  LOG_OVER_TIME_STATUS_COLOR,
  CLIENT_STATUS,
  PROJECT_EXTERIOR_STATUS,
  PROJECT_EXTERIOR_STATUS_COLOR,
  PROJECT_EXTERIOR_URGENRY_COLOR,
  PROJECT_TYPE,
  NEWS_STATUS,
};
