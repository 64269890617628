import { ENUMS, Routers } from ".";

const PERMISSIONS = {
  [Routers.DASHBOARD]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MARKETING,
    ],
    notAllowed: [],
  },
  [Routers.NOTIFICATION]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MARKETING,
    ],
    notAllowed: [],
  },
  [Routers.PROJECT]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MARKETING,
    ],
    notAllowed: [],
  },
  [Routers.CREATE_PROJECT]: {
    allowed: [ENUMS.ROLES.ADMIN, ENUMS.ROLES.MANAGER],
    notAllowed: [
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MARKETING,
    ],
  },
  [Routers.UPDATE_PROJECT]: {
    allowed: [ENUMS.ROLES.ADMIN, ENUMS.ROLES.MANAGER, ENUMS.ROLES.LEADER],
    notAllowed: [],
  },
  [Routers.CREATE_PROJECT_EXTERIOR]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    notAllowed: [ENUMS.ROLES.LEADER, ENUMS.ROLES.ARTIST, ENUMS.ROLES.MARKETING],
  },
  [Routers.UPDATE_PROJECT_EXTERIOR]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    notAllowed: [ENUMS.ROLES.ARTIST],
  },
  [Routers.USER]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    notAllowed: [ENUMS.ROLES.LEADER, ENUMS.ROLES.ARTIST, ENUMS.ROLES.MARKETING],
  },
  [Routers.USER_CREATE]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    notAllowed: [ENUMS.ROLES.LEADER, ENUMS.ROLES.ARTIST, ENUMS.ROLES.MARKETING],
  },
  [Routers.USER_UPDATE]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    notAllowed: [ENUMS.ROLES.LEADER, ENUMS.ROLES.ARTIST, ENUMS.ROLES.MARKETING],
  },
  [Routers.PERFORMANCE]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.LEADER,
    ],
    notAllowed: [ENUMS.ROLES.ARTIST, ENUMS.ROLES.MARKETING],
  },
  [Routers.INTERNAL_IP]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    notAllowed: [ENUMS.ROLES.LEADER, ENUMS.ROLES.ARTIST, ENUMS.ROLES.MARKETING],
  },
  [Routers.NEWS]: {
    allowed: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MARKETING,
    ],
    notAllowed: [],
  },
};

const ACCESS_MODULES = {
  Project: {
    viewUser: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    createProject: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    editProject: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    deleteProject: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    changeStatus: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    editSomeField: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    deleteMember: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    createMember: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    fetchProject: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.ARTIST,
    ],
    canViewScreenProjectExterior: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MARKETING,
    ],
    changeStatusExterior: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    createMemberExterior: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    deleteMemberExterior: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
  },
  Board: {
    createProccess: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.ARTIST,
    ],
    editProcess: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.MARKETING,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    deleteProcess: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    deleteComment: [ENUMS.ROLES.ADMIN, ENUMS.ROLES.MANAGER, ENUMS.ROLES.LEADER],
  },
  DayOffRequest: {
    approve: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    fetchAggregate: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    deleteDayOff: [ENUMS.ROLES.ADMIN],
  },
  UpdateTimecard: {
    approve: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MANAGER,
    ],
    sendUpdateTimeCard: [
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.MARKETING,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.ADMIN,
    ],
    deleteUpdateTimecard: [ENUMS.ROLES.ADMIN],
  },
  Overtime: {
    sendOvertime: [ENUMS.ROLES.ADMIN, ENUMS.ROLES.MANAGER, ENUMS.ROLES.LEADER],
    changeStatus: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    deleteOvertime: [ENUMS.ROLES.ADMIN],
    checkTable: [ENUMS.ROLES.ARTIST],
    approved: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    totalOvertime: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    filterKeyword: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.LEADER,
    ],
    viewTable: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
  },
  LogTime: {
    createInternalLog: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.ARTIST,
    ],
    approve: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MARKETING,
    ],
    createExternalLog: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MANAGER,
    ],
    updateLog: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    deleteLog: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    updateLogStatus: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.MANAGER,
    ],
    filterKeyword: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    totalOveview: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    createInternalLogArtist: [
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.LEADER,
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.MARKETING,
    ],
  },
  LeaveDays: {
    checkLeaveDays: [
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
    ],
  },
  Users: {
    fetchUsers: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    check: [ENUMS.ROLES.HUMAN_RESOURCES, ENUMS.ROLES.ADMIN],
    checkRole: [ENUMS.ROLES.ADMIN],
    updateProfile: [
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
    ],
    viewProfile: [
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
    ],
    createUser: [ENUMS.ROLES.HUMAN_RESOURCES, ENUMS.ROLES.ADMIN],
    checkEditEmail: [ENUMS.ROLES.ADMIN],
    CheckPassWord: [
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
    ],
    checkDeleteUser: [
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
    ],
    checkCreateUser: [
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
    ],
    fetchUserDashboard: [ENUMS.ROLES.ADMIN],
  },
  Timekeeping: {
    checkUpdate: [
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
    ],
    tableTimekeeping: [
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
    ],
    export: [
      ENUMS.ROLES.HUMAN_RESOURCES,
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
    ],
  },
  Timesheet: {
    updateTimesheet: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    filter: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
  },
  setting: {
    setting: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    news: [ENUMS.ROLES.ADMIN, ENUMS.ROLES.MANAGER, ENUMS.ROLES.HUMAN_RESOURCES],
    internal: [ENUMS.ROLES.ADMIN, ENUMS.ROLES.HUMAN_RESOURCES],
    internalStaff: [
      ENUMS.ROLES.ARTIST,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.MARKETING,
      ENUMS.ROLES.LEADER,
    ],
    isCheckWarning: [ENUMS.ROLES.ADMIN],
  },
  Holiday: {
    create: [ENUMS.ROLES.ADMIN, ENUMS.ROLES.HUMAN_RESOURCES],
  },
  Performance: {
    editAmountOfWork: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
    export: [
      ENUMS.ROLES.ADMIN,
      ENUMS.ROLES.MANAGER,
      ENUMS.ROLES.HUMAN_RESOURCES,
    ],
  },
};

export { PERMISSIONS, ACCESS_MODULES };
