import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Stack, Heading, Box, Button, HStack, Icon } from "@chakra-ui/react";
import { AdminLayout } from "@/Components/DefaultLayout";

import { ConfirmDialog } from "@/Components/Popup";

import { NewsActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import Utils from "@/Utils";

import CreateNewsDialog from "@/Components/Popup/News/CreateNewsDialog";
import UpdateNewsDialog from "@/Components/Popup/News/UpdateNewsDialog";

import { INewsStructure } from "@/Interfaces/News.interface";
import NewsDataTable from "@/Components/LayoutPart/DataTable/NewsDataTable";
import { PlusIcon } from "@heroicons/react/24/outline";
import { RoundedContainer } from "@/Components/Common";
import { useTranslation } from "react-multi-lang";

const { fetchNews, changeStatusNews } = NewsActions;

const DEFAULT_FILTERS = {
  page: 1,
  limit: 10,
};

const News: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const newsList: INewsStructure[] = useSelector((state: RootState) =>
    _.get(state.NEWS, "newsList")
  );
  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.NEWS, "isFetchLoading")
  );
  const isChangeStatusSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.NEWS, "isChangeStatusSuccess")
  );

  const pagination: any = useSelector((state: RootState) =>
    _.get(state.NEWS, "pagination")
  );

  const meta = useSelector((state: RootState) => _.get(state.NEWS, "meta"));

  const [confirmStatus, setConfirmStatus] = useState<{
    open: boolean;
    status: string;
    id: string;
  }>({ open: false, status: "", id: "" });

  const [isShowPopup, setIsShowPopup] = useState({
    create: false,
    update: false,
  });

  const [newsDetail, setNewsDetail] = useState<INewsStructure>({
    id: "",
    title: "",
    content: "",
    status: "",
  });

  const sortedNewsList = useMemo(() => {
    return !_.isEmpty(newsList)
      ? Utils.sortByProperty(newsList, "updatedAt", false)
      : [];
  }, [newsList]);

  useEffect(() => {
    dispatch(fetchNews(DEFAULT_FILTERS));
  }, []);

  useEffect(() => {
    if (isChangeStatusSuccess) {
      setConfirmStatus({ open: false, status: "", id: "" });
    }
  }, [isChangeStatusSuccess]);

  const handlePopupChange = (name: string, value: boolean) =>
    setIsShowPopup({ ...isShowPopup, [name]: value });

  const onSwitchStatus = (type: boolean, id: string) => {
    setConfirmStatus({
      open: true,
      status: type ? "active" : "inactive",
      id: id,
    });
  };

  const onChangeStatus = () => {
    if (confirmStatus?.id) {
      dispatch(
        changeStatusNews(confirmStatus.id, { status: confirmStatus.status })
      );
    }
  };

  const onRowActionChange = (action: string, item: any) => {
    if (action === "edit") {
      setNewsDetail(item);
      handlePopupChange("update", true);
    }
    if (action === "delete") {
      setConfirmStatus({
        open: true,
        status: "inactive",
        id: item?.id,
      });
    }
  };

  const onPageChange = (value: number) =>
    dispatch(fetchNews({ ...pagination, page: value }));
  const onChangeLimit = (limit: number) =>
    dispatch(fetchNews({ ...pagination, page: 1, limit }));

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box>
          <HStack
            sx={{
              display: "flex",
              justifyContent: "space-between",
              w: "full",
            }}
          >
            <Heading size="md" color={"#5C6e6c"}>
              {t("title.news")}
            </Heading>
            <Button
              size="sm"
              onClick={() => handlePopupChange("create", true)}
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.create")}
            </Button>
          </HStack>
        </Box>
        <RoundedContainer>
          <NewsDataTable
            paginate={meta}
            payload={sortedNewsList}
            onRowAction={onRowActionChange}
            onSwitchStatus={onSwitchStatus}
            onPageChange={onPageChange}
            isLoading={isFetchLoading}
            onChangeLimit={onChangeLimit}
          />
        </RoundedContainer>
        <CreateNewsDialog
          onClose={() => handlePopupChange("create", false)}
          open={isShowPopup.create}
        />
        <UpdateNewsDialog
          onClose={() => handlePopupChange("update", false)}
          open={!!newsDetail && isShowPopup.update}
          payload={newsDetail}
        />
        <ConfirmDialog
          isOpen={confirmStatus.open}
          onClose={() => setConfirmStatus({ ...confirmStatus, open: false })}
          body={t("message.confirmAction")}
          onAction={() => onChangeStatus()}
          actionType={"confirm"}
        />
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default News;
