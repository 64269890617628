import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  Stack,
  Heading,
  Grid,
  GridItem,
  Text,
  Box,
  //  Card,
  //  CardHeader,
  //  CardBody,
  CircularProgress,
  Tabs,
  TabList,
  Tab,
  //  Image,
} from "@chakra-ui/react";
import { AdminLayout } from "@/Components/DefaultLayout";
import { RootState, useTypedDispatch } from "@/Store";
import {
  HolidayActions,
  ProjectActions,
  DayOffRequestActions,
  ChatActions,
  WorkingDayActions,
  NewsActions,
} from "@/Actions";
import { ENUMS } from "@/Constants";

import StatisticBox from "./StatisticBox";
import TimeSheet from "./TimeSheet";
import NewsList from "./NewsList";
//import { IProjectStructure } from "@/Interfaces/Project.interface";
import {
  //  AvatarGroupWithOverflow,
  //  TextComponent,
  ChartComponent,
} from "@/Components/Common";
import { useWindowWidth } from "@/Helpers";

import processImage from "@/Assets/process.svg";
import pendingImage from "@/Assets/pending.svg";
import historyImage from "@/Assets/history.svg";
import finalImage from "@/Assets/final.svg";
import LogTimeAction from "@/Actions/LogTime.action";
import DealineDashboardDataTable from "@/Components/LayoutPart/DataTable/DealineDashboardDataTable";
import TimeSheetMyWork from "./TimesheetsMyWork";
import { ConfirmDialog, TimeLogDialog } from "@/Components/Popup";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

const {
  //  fetchProjectsForDashboard,
  getStatisticalProjects,
  resetProjectReducer,
} = ProjectActions;
const { fetchNews } = NewsActions;
const { fetchDayOffRequest, resetDayOffRequestReducer } = DayOffRequestActions;
const { fetchDealineDashboard, resetLogTimePerformAction, deleteLogTime } =
  LogTimeAction;
const { fetchHoliday, resetHolidayReducer } = HolidayActions;
const { fetchAllUsers } = ChatActions;
const { fetchWorkingDay, resetWorkingDayReducer } = WorkingDayActions;

const { PROJECT_STATUS_COLOR } = ENUMS;

const Dashboard: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const [openMyWork, setOpenMyWork] = useState<string>("overview");
  const [searchParams, setSearchParams] = useSearchParams();

  const statistical = useSelector((state: RootState) =>
    _.get(state.PROJECT, "statistical")
  );

  const deline = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "logTimeDeadlineDashboard")
  );

  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isGetLoading")
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.PROJECT, "isFetchLoading")
  );

  const timeLogPerformAction: any = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "performAction")
  );

  const isDeleteLogSuccess = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isDeleteSuccess")
  );

  const isUpdateSuccess = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isUpdateSuccess")
  );

  useEffect(() => {
    dispatch(fetchWorkingDay());
    dispatch(fetchAllUsers());
    dispatch(getStatisticalProjects());
    dispatch(fetchNews);
    dispatch(fetchDealineDashboard({ page: 0, limit: 0 }));
    dispatch(
      fetchDayOffRequest({
        status: ENUMS.DAY_OFF_REQUEST_STATUS.APPROVED,
        page: 0,
        limit: 0,
      })
    );
    return () => {
      dispatch(resetProjectReducer());
      dispatch(resetDayOffRequestReducer());
      dispatch(resetWorkingDayReducer());
    };
  }, []);

  useEffect(() => {
    if (isDeleteLogSuccess) {
      dispatch(resetLogTimePerformAction());
    }
  }, [isDeleteLogSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(fetchDealineDashboard({ page: 0, limit: 0 }));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    dispatch(fetchHoliday({ page: 0, limit: 0, status: "active" }));
    return () => {
      dispatch(resetHolidayReducer());
    };
  }, []);

  const _renderStatisticSection = () => {
    return (
      <>
        <Grid templateColumns="repeat(4, 1fr)" gap={isMobile ? 2 : 3}>
          <GridItem>
            <StatisticBox
              label={t("label.waitingProcess")}
              value={statistical?.[ENUMS.PROJECT_STATUS.WAITING_PROCESS] || 0}
              sxContainer={{
                //  backgroundImage: `url("${staticGrayImage}")`,
                background: "rgba(92, 110, 108, 0.6)",
              }}
              icon={pendingImage}
              isLoading={isGetLoading}
            />
          </GridItem>
          <GridItem>
            <StatisticBox
              label={t("label.ongoing")}
              value={
                (statistical?.[ENUMS.PROJECT_STATUS.STARTING] || 0) +
                (statistical?.[ENUMS.PROJECT_STATUS.IN_PROCESS] || 0)
              }
              sxContainer={{
                //  backgroundImage: `url("${staticBlueImage}")`,
                background: "rgba(210, 169, 106,0.6)",
              }}
              icon={processImage}
              isLoading={isGetLoading}
            />
          </GridItem>
          <GridItem>
            <StatisticBox
              label={t("label.finalDeliver")}
              value={statistical?.[ENUMS.PROJECT_STATUS.FINAL_DELIVER] || 0}
              sxContainer={{
                //  backgroundImage: `url("${staticGreenImage}")`,
                background: "rgba(187, 113, 84, 0.6)",
              }}
              icon={finalImage}
              isLoading={isGetLoading}
            />
          </GridItem>
          <GridItem>
            <StatisticBox
              label={t("label.history")}
              value={statistical?.[ENUMS.PROJECT_STATUS.HISTORY] || 0}
              sxContainer={{
                //  backgroundImage: `url("${staticYellowImage}")`,
                background: "rgba(166, 183, 170, 0.6)",
              }}
              icon={historyImage}
              isLoading={isGetLoading}
            />
          </GridItem>
        </Grid>
        <NewsList />
      </>
    );
  };

  const _renderTopSection = () => {
    return (
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Tabs>
          <TabList
            sx={{
              width: "100%",
            }}
          >
            <Tab
              sx={{
                w: 200,
                fontSize: 16,
              }}
              _selected={{
                color: "#bb7154",
                fontWeight: 600,
                borderBottom: "4px solid #bb7154",
              }}
              onClick={() => setOpenMyWork("overview")}
            >
              {t("label.overview")}
            </Tab>
            <Tab
              sx={{
                w: 200,
                fontSize: 16,
              }}
              _selected={{
                color: "#bb7154",
                fontWeight: 600,
                borderBottom: "4px solid #bb7154",
              }}
              onClick={() => setOpenMyWork("myWork")}
            >
              {t("label.myWork")}
            </Tab>
          </TabList>
        </Tabs>
      </Stack>
    );
  };

  const _renderTimesheetSection = (typeChat: string) => {
    switch (typeChat) {
      case "overview":
        return <TimeSheet />;
      case "myWork":
        return <TimeSheetMyWork />;
      default:
        return;
    }
  };

  const _renderPopup = () => {
    return (
      <>
        <TimeLogDialog.UpdateTimelogDialog
          open={timeLogPerformAction?.actionType === "editLog"}
          onClose={() => {
            if (searchParams) {
              searchParams.delete("id");
              setSearchParams(searchParams);
            }
            dispatch(resetLogTimePerformAction());
          }}
        />
        <ConfirmDialog
          isOpen={timeLogPerformAction?.actionType === "deleteLog"}
          onClose={() => dispatch(resetLogTimePerformAction())}
          onAction={() => {
            dispatch(deleteLogTime(timeLogPerformAction.id));
          }}
          body={t("message.doYouWantRemoveThisLog")}
          actionType="delete"
        />
      </>
    );
  };

  const _renderDeadline = () => {
    return (
      <Box>
        <DealineDashboardDataTable payload={deline} />
      </Box>
    );
  };

  const _generatePieData = () => {
    const labels = [
      t("label.waitingProcessProjects"),
      t("label.startingProjects"),
      t("label.onGoingProjects"),
      t("label.finalDeliverProjects"),
      t("label.historyProjects"),
    ];
    const backgroundColor = [
      PROJECT_STATUS_COLOR["waiting process"].rgba,
      PROJECT_STATUS_COLOR["starting"].rgba,
      PROJECT_STATUS_COLOR["in process"].rgba,
      PROJECT_STATUS_COLOR["final deliver"].rgba,
      PROJECT_STATUS_COLOR["history"].rgba,
    ];
    return {
      labels,
      datasets: [
        {
          data: _.map(ENUMS.PROJECT_STATUS, (key) => statistical[key]),
          backgroundColor,
          hoverOffset: 6,
        },
      ],
    };
  };

  const totalProjects = () => {
    let total = 0;
    _.forEach(ENUMS.PROJECT_STATUS, (key) => {
      total += statistical[key];
    });
    return total;
  };

  const _renderBottomSection = () => (
    <Stack
      direction={{ base: "column", md: "row" }}
      spacing={2}
      textAlign={"center"}
    >
      <Box flex={1}>
        <Box>
          <Text fontSize="18px" fontWeight={500} sx={{ mb: 3 }}>
            {t("label.projectsStatistical")}
          </Text>
          {isGetLoading && <CircularProgress size="18px" isIndeterminate />}
        </Box>
        {!isGetLoading && (
          <ChartComponent.DoughnutChart
            title=""
            centerText={`${totalProjects() || 0} ${t("label.projects")}`}
            data={_generatePieData()}
            subtitle={false}
          />
        )}
      </Box>
      <Box flex={2}>
        <Box>
          <Text fontSize="18px" fontWeight={500} sx={{ mb: "10px !important" }}>
            {t("label.processNeedCompletedToday")}
          </Text>
          {isFetchLoading && <CircularProgress size="18px" isIndeterminate />}
        </Box>
        {/*{_renderProjectCards()}*/}
        {_renderDeadline()}
        {_renderPopup()}
      </Box>
    </Stack>
  );

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Heading size="md" color={"#5C6e6c"}>
          {t("title.dashboard")}
        </Heading>
        {_renderStatisticSection()}
        {_renderTopSection()}
        {_renderTimesheetSection(openMyWork)}
        {_renderBottomSection()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default Dashboard;
