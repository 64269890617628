import React from "react";
import isEmpty from "lodash/isEmpty";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify"; // Ensure this library is installed: npm install dompurify

import { Box, FormLabel, FormErrorMessage } from "@chakra-ui/react";

// Define types manually to avoid Quill namespace issues
type DeltaStatic = {
  ops: Array<{ insert: string | object; attributes?: object }>;
};

interface TextEditorNewsProps {
  label: string;
  labelSx?: object;
  containerSx?: object;
  value: string;
  onChange?: (newValue: string) => void;
  message?: string;
  placeholder?: string;
  direction?: "column" | "row";
  required?: boolean;
  disabled?: boolean;
  height?: number;
}

const TextEditorNews: React.FC<TextEditorNewsProps> = ({
  label,
  containerSx,
  value,
  onChange,
  message = "",
  placeholder = "",
  direction = "column",
  disabled = false,
  height = 200,
}) => {
  // Sanitize and process editor content
  const handleEditorChange = (content: string) => {
    const sanitizedContent = DOMPurify.sanitize(content, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: [
        "allow",
        "allowfullscreen",
        "frameborder",
        "scrolling",
        "referrerpolicy",
      ],
    });
    if (onChange && !disabled) onChange(sanitizedContent);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // Headers
      ["bold", "italic", "underline", "strike"], // Text styles
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      [{ color: [] }, { background: [] }], // Text color and background
      [{ align: [] }], // Text alignment
      ["link", "image", "video"], // Links, images, and videos
      ["blockquote", "code-block"], // Blockquote and code block
      [{ script: "sub" }, { script: "super" }], // Subscript and superscript
      ["clean"], // Clear formatting
    ],
    clipboard: {
      matchVisual: false,
      matchers: [
        [
          Node.TEXT_NODE,
          (node: Node, delta: DeltaStatic) => {
            const text = (node as Text).data;
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const matches = text.match(urlRegex);
            if (matches) {
              return matches.reduce(
                (acc: DeltaStatic, url: string) => {
                  acc.ops.push({ insert: url, attributes: { link: url } });
                  acc.ops.push({ insert: " " });
                  return acc;
                },
                { ops: [] } as DeltaStatic
              );
            }
            return delta;
          },
        ],
      ],
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "color",
    "background",
    "align",
    "link",
    "image",
    "video",
    "blockquote",
    "code-block",
    "script",
  ];

  return (
    <Box
      className={!isEmpty(message) ? "required" : ""}
      sx={{
        display: "flex",
        flexDirection: direction,
        ...containerSx,
        ".quill": {
          minHeight: `${height}px`,
          boxSizing: "border-box",
          "&:hover .ql-toolbar.ql-snow, &:hover .ql-container.ql-snow": {
            borderColor: message ? "#d32f2f" : "black",
          },
          "&:focus-within .ql-toolbar.ql-snow": {
            outline: `2px solid ${message ? "#d32f2f" : "#3498db"}`,
            outlineOffset: "-2px",
          },
          "&:focus-within .ql-container.ql-snow": {
            borderWidth: "2px",
            borderColor: message ? "#d32f2f" : "#3498db",
          },
        },
        ".ql-toolbar.ql-snow": {
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          borderColor: message ? "#d32f2f" : "#ccc",
        },
        ".ql-container.ql-snow": {
          height: `${height}px`,
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          borderColor: message ? "#d32f2f" : "#ccc",
        },
        ".ql-editor iframe": {
          width: "100%",
          maxWidth: "100%",
          height: "auto",
          aspectRatio: "16/9",
          border: "none",
        },
      }}
    >
      {label ? (
        <FormLabel fontSize="14" fontWeight={700}>
          {label}
        </FormLabel>
      ) : null}
      <Box
        sx={{
          pointerEvents: disabled ? "none" : "unset",
          color: disabled ? "rgba(0, 0, 0, 0.38)" : "black",
          flex: 1,
        }}
      >
        <ReactQuill
          className="default-font-size"
          modules={modules}
          formats={formats}
          theme="snow"
          value={value}
          onChange={handleEditorChange}
          onKeyDown={(e: React.KeyboardEvent) => disabled && e.preventDefault()}
          placeholder={placeholder}
          style={{ height: `${height}px` }}
        />
        {message && (
          <FormErrorMessage
            ml={2}
            mt={0.5}
            sx={{ fontSize: "13px" }}
            color="error"
          >
            {message}
          </FormErrorMessage>
        )}
      </Box>
    </Box>
  );
};

export default TextEditorNews;
