import * as AuthenticationApi from "./Authentication.api";
import * as RoleApi from "./Role.api";
import * as UserApi from "./User.api";
import * as ProjectApi from "./Project.api";
import * as BoardApi from "./Board.api";
import * as LogtimeAPI from "./LogTime.api";
import * as DayOffRequestAPI from "./DayOffRequest.api";
import * as LeaveDaysAPI from "./LeaveDays.api";
import * as RequsetUpdateTimeCardAPI from "./RequestUpdateTimecard.api";
import * as TimekeepingdAPI from "./Timekeeping.api";
import * as TimesheetAPI from "./Timesheet.api";
import * as InternalIpAPI from "./InternalIp.api";
import * as HolidayAPI from "./Holiday.api";
import * as PerformanceAPI from "./Performance.api";
import * as NotificationAPI from "./Notification.api";
import * as ChatAPI from "./Chat.api";
import * as TaskNoteAPI from "./TaskNote.api";
import * as Client from "./Client.api";
import * as WorkingTime from "./WorkingTime.api";
import * as ProjectExteriorApi from "./ProjectExterior.api";
import * as BackupApi from "./Backup.api";
import * as TimeDeleteFilePrivateChatApi from "./TimeDeleteFilePrivateChat.api";
import * as MailReceiveApi from "./MailReceive.api";
import * as WorkspaceApi from "./Workspace.api";
import * as WorkingDayApi from "./WorkingDay.api";
import * as News from "./News.api";

export default {
  ...AuthenticationApi,
  ...RoleApi,
  ...UserApi,
  ...ProjectApi,
  ...BoardApi,
  ...LogtimeAPI,
  ...DayOffRequestAPI,
  ...LeaveDaysAPI,
  ...RequsetUpdateTimeCardAPI,
  ...TimekeepingdAPI,
  ...TimesheetAPI,
  ...InternalIpAPI,
  ...HolidayAPI,
  ...PerformanceAPI,
  ...NotificationAPI,
  ...ChatAPI,
  ...TaskNoteAPI,
  ...Client,
  ...WorkingTime,
  ...ProjectExteriorApi,
  ...BackupApi,
  ...TimeDeleteFilePrivateChatApi,
  ...MailReceiveApi,
  ...WorkspaceApi,
  ...WorkingDayApi,
  ...News,
};
