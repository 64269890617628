import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { ICreateNews } from "@/Interfaces/News.interface";

const { NEWS } = APIs;

export const createNews = async (payload: ICreateNews) => {
  return request(NEWS.BASIC, "POST_FORM_DATA", payload);
};

export const fetchNews = async (payload: any) => {
  return request(NEWS.BASIC, "GET", payload);
};

export const updateNews = async (id: string, payload: ICreateNews) => {
  return request(`${NEWS.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const changeStatusNews = async (id: string, payload: any) => {
  return request(`${NEWS.CHANGE_STATUS}/${id}`, "PUT", payload);
};

export const getNewsById = async (id: string) => {
  return request(`${NEWS.GET_BY_ID}/${id}`, "GET");
};