import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import { ICreateNews } from "@/Interfaces/News.interface";
import { IPaginate } from "@/Types/Common.types";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import _ from "lodash";

const setNewsFetchLoading = () => {
  return {
    type: ActionTypes.SET_NEWS_FETCH_LOADING,
  };
};

const setNewsGetLoading = () => {
  return {
    type: ActionTypes.SET_NEWS_GET_LOADING,
  };
};

const resetStatusNews = () => {
  return {
    type: ActionTypes.RESET_STATUS_NEWS,
  };
};

const setNewsActionLoading = () => {
  return {
    type: ActionTypes.SET_NEWS_ACTION_LOADING,
  };
};

const resetNewsReducer = () => {
  return {
    type: ActionTypes.RESET_NEWS,
  };
};

const setMetaNews = (payload: IPaginate) => {
  return {
    type: ActionTypes.SET_META_NEWS,
    payload,
  };
};

const setPaginationNews = (payload?: any) => {
  return {
    type: ActionTypes.SET_PAGINATION_NEWS,
    payload,
  };
};

const createNewsSuccess = () => {
  return {
    type: ActionTypes.CREATE_NEWS_SUCCESS,
  };
};

const createNewsFail = () => {
  return {
    type: ActionTypes.CREATE_NEWS_FAILURE,
  };
};

const createNews = (payload: ICreateNews) => {
  return async (dispatch: any) => {
    dispatch(setNewsActionLoading());
    dispatch(resetStatusNews());
    await APIs.createNews(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(createNewsFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createNewsSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchNews());
        }
      })
      .catch(async (error) => {
        await dispatch(createNewsFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchNewsSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_NEWS_SUCCESS,
    payload,
  };
};

const fetchNewsFail = () => {
  return {
    type: ActionTypes.FETCH_NEWS_FAILURE,
  };
};

const fetchNews = (payload?: any) => {
  return async (dispatch: any) => {
    dispatch(setNewsFetchLoading());
    await APIs.fetchNews(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchNewsFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(setMetaNews(_.get(result, "meta")));
          await dispatch(setPaginationNews(payload));
          await dispatch(fetchNewsSuccess(resolveResult.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchNewsFail());
      });
  };
};

const getByIdNewsSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_BY_ID_NEWS_SUCCESS,
    payload,
  };
};

const getByIdNewsFail = () => {
  return {
    type: ActionTypes.GET_BY_ID_NEWS_FAILURE,
  };
};

const getByIdNews = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setNewsGetLoading());
    await APIs.getNewsById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdNewsFail());
        else {
          dispatch(getByIdNewsSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getByIdNewsFail());
      });
  };
};

const updateNewsSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_NEWS_SUCCESS,
    payload,
  };
};

const updateNewsFail = () => {
  return {
    type: ActionTypes.UPDATE_NEWS_FAILURE,
  };
};

const updateNews = (id: string, payload: ICreateNews, pagination?: any) => {
  return async (dispatch: any) => {
    dispatch(setNewsFetchLoading());
    dispatch(resetStatusNews());
    await APIs.updateNews(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateNewsFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(updateNewsSuccess(result));
          dispatch(fetchNews(pagination));
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchNews());
        }
      })
      .catch(async (error) => {
        await dispatch(updateNewsFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const changeStatusNewsSuccess = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_NEWS_SUCCESS,
  };
};

const changeStatusNewsFail = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_NEWS_FAILURE,
  };
};

const changeStatusNews = (id: string, payload: any) => {
  return async (dispatch: any) => {
    dispatch(setNewsActionLoading());
    await APIs.changeStatusNews(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(changeStatusNewsFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(changeStatusNewsSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchNews());
        }
      })
      .catch(async (error: any) => {
        await dispatch(changeStatusNewsFail());
        await Toast({
          title: error?.message,
          description: error?.message,
          status: "error",
        });
      });
  };
};

export default {
  resetNewsReducer,
  getByIdNews,
  fetchNews,
  updateNews,
  changeStatusNews,
  createNews,
};
