import { Navigate, Outlet } from "react-router-dom";
import { Routers } from "@/Constants";
import { MainPages } from "@/Pages";
import ProtectedRoute from "./ProtectedRouters";

const isLoggedIn = true;

const MainRouters = {
  element: isLoggedIn ? <Outlet /> : <Navigate to={Routers.ERROR_404} />,
  path: Routers.SIGN_IN,
  children: [
    {
      path: Routers.DASHBOARD,

      element: (
        <ProtectedRoute location={Routers.DASHBOARD}>
          <MainPages.Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.USER,
      element: (
        <ProtectedRoute location={Routers.USER}>
          <MainPages.User.UserList />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.USER_UPDATE,
      element: (
        <ProtectedRoute location={Routers.USER_UPDATE}>
          <MainPages.User.UpdateUser />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.USER_CREATE,
      element: (
        <ProtectedRoute location={Routers.USER_CREATE}>
          <MainPages.User.CreateUser />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.DASHBOARD,
      element: (
        <ProtectedRoute location={Routers.DASHBOARD}>
          <MainPages.Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.PROJECT,
      element: (
        <ProtectedRoute location={Routers.PROJECT}>
          <MainPages.Project.ProjectList />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.CREATE_PROJECT,
      element: (
        <ProtectedRoute location={Routers.CREATE_PROJECT}>
          <MainPages.Project.CreateProject />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.UPDATE_PROJECT,
      element: (
        <ProtectedRoute location={Routers.UPDATE_PROJECT}>
          <MainPages.Project.UpdateProject />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.PROJECT_DETAILS,
      element: (
        <ProtectedRoute location={Routers.PROJECT_DETAILS}>
          <MainPages.Project.ProjectDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.PERFORMANCE,
      element: (
        <ProtectedRoute location={Routers.PERFORMANCE}>
          <MainPages.Performance />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.BOARD,
      element: (
        <ProtectedRoute location={Routers.BOARD}>
          <MainPages.Board.BoardOverview />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.BOARD_DETAIL,
      element: (
        <ProtectedRoute location={Routers.BOARD_DETAIL}>
          <MainPages.Board.BoardProject />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.TIME_SHEET,
      element: (
        <ProtectedRoute location={Routers.TIME_SHEET}>
          <MainPages.TimeCard.Timesheet />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.CHAT,
      element: (
        <ProtectedRoute location={Routers.CHAT}>
          <MainPages.Chat />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.MY_TIME,
      element: (
        <ProtectedRoute location={Routers.MY_TIME}>
          <MainPages.MyTime />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.PROFILE,
      element: (
        <ProtectedRoute location={Routers.PROFILE}>
          <MainPages.Profile />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.OVER_TIME,
      element: (
        <ProtectedRoute location={Routers.OVER_TIME}>
          <MainPages.TimeCard.OverTime />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.DAY_OFF_REQUEST,
      element: (
        <ProtectedRoute location={Routers.DAY_OFF_REQUEST}>
          <MainPages.TimeCard.TimeOffRequest />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.LEAVE_DAY,
      element: (
        <ProtectedRoute location={Routers.LEAVE_DAY}>
          <MainPages.TimeCard.LeaveDays />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.REQUEST_UPDATE_TIME_CARD,
      element: (
        <ProtectedRoute location={Routers.REQUEST_UPDATE_TIME_CARD}>
          <MainPages.TimeCard.RequestUpdateTimeCard />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.TIME_KEEPPING,
      element: (
        <ProtectedRoute location={Routers.TIME_KEEPPING}>
          <MainPages.TimeCard.CheckinHistory />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.NOTIFICATION,
      element: (
        <ProtectedRoute location={Routers.NOTIFICATION}>
          <MainPages.Notification />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.HOLIDAYS,
      element: (
        <ProtectedRoute location={Routers.HOLIDAYS}>
          <MainPages.HolidayList />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.INTERNAL_IP,
      element: (
        <ProtectedRoute location={Routers.INTERNAL_IP}>
          <MainPages.Setting.InternalIP />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.NEWS,
      element: (
        <ProtectedRoute location={Routers.NEWS}>
          <MainPages.Setting.News/>
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.CLIEINT,
      element: (
        <ProtectedRoute location={Routers.CLIEINT}>
          <MainPages.Client />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.WORKING_TIME,
      element: (
        <ProtectedRoute location={Routers.WORKING_TIME}>
          <MainPages.WorkingTimeList />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.WORKING_DAY,
      element: (
        <ProtectedRoute location={Routers.WORKING_DAY}>
          <MainPages.WorkingDay />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.SHARE_TIMELINE,
      element: <MainPages.Project.ShareTimelineToClient />,
    },
    {
      path: Routers.CREATE_PROJECT_EXTERIOR,
      element: (
        <ProtectedRoute location={Routers.CREATE_PROJECT_EXTERIOR}>
          <MainPages.Project.CreateExteriorProject />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.UPDATE_PROJECT_EXTERIOR,
      element: (
        <ProtectedRoute location={Routers.UPDATE_PROJECT_EXTERIOR}>
          <MainPages.Project.UpdateExteriorProject />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.DETAIL_PROJECT_EXTERIOR,
      element: (
        <ProtectedRoute location={Routers.DETAIL_PROJECT_EXTERIOR}>
          <MainPages.Project.ProjectExteriorDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.GET_MY_INTERNAL_IP,
      element: (
        <ProtectedRoute location={Routers.GET_MY_INTERNAL_IP}>
          <MainPages.GetMyIp />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.BACKUP_RESTORE,
      element: (
        <ProtectedRoute location={Routers.BACKUP_RESTORE}>
          <MainPages.Setting.BackupRestore />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.FILE_SYSTEM,
      element: (
        <ProtectedRoute location={Routers.FILE_SYSTEM}>
          <MainPages.Setting.FileSystem.Overview />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.WORKSPACE,
      element: (
        <ProtectedRoute location={Routers.WORKSPACE}>
          <MainPages.Setting.Workspace />
        </ProtectedRoute>
      ),
    },
    {
      path: Routers.WORKSPACE_GROUP,
      element: (
        <ProtectedRoute location={Routers.WORKSPACE_GROUP}>
          <MainPages.Setting.workspaceIframe />
        </ProtectedRoute>
      ),
    },
  ],
};

export default MainRouters;
