import React from "react";
import isEmpty from "lodash/isEmpty";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Box, FormLabel, FormErrorMessage } from "@chakra-ui/react";

interface TextEditorNewsProps {
  label: string;
  labelSx?: object;
  containerSx?: object;
  value: string;
  onChange?: (newValue: string) => void;
  message?: string;
  placeholder?: string;
  direction?: "column" | "row";
  required?: boolean;
  disabled?: boolean;
  height?: number;
}

const TextEditorNews: React.FC<TextEditorNewsProps> = ({
  label,
  containerSx,
  value,
  onChange,
  message = "",
  placeholder = "",
  direction = "column",
  disabled = false,
  height = 200,
}) => {
  const handleEditorChange = (
    content: string,
    _delta: any,
    _source: any,
    editor: any
  ) => {
    let resolveValue = content;
    if (
      editor
        .getText()
        .replace(/<(.|\n)*?>/g, "")
        .trim().length === 0
    ) {
      resolveValue = "";
    } else resolveValue = content;

    if (onChange && !disabled) onChange(resolveValue);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // Headers
      ["bold", "italic", "underline", "strike"], // Text styles
      [
        { list: "ordered" },
        { list: "bullet" },
      ], // Lists
      [{ color: [] }, { background: [] }], // Text color and background
      [{ align: [] }], // Text alignment
      ["link", "image", "video"], // Links, images, and videos
      ["clean"], // Clear formatting
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "color",
    "background",
    "align",
    "link",
    "image",
    "video",
  ];

  return (
    <Box
      className={!isEmpty(message) ? "required" : ""}
      sx={{
        display: "flex",
        flexDirection: direction,
        ...containerSx,
        ".quill": {
          minHeight: `${height}px`,
          boxSizing: "border-box",
          "&:hover .ql-toolbar.ql-snow, &:hover .ql-container.ql-snow": {
            borderColor: message ? "#d32f2f" : "black",
          },
          "&:focus-within .ql-toolbar.ql-snow": {
            outline: `2px solid ${message ? "#d32f2f" : "#3498db"}`,
            outlineOffset: "-2px",
          },
          "&:focus-within .ql-container.ql-snow": {
            borderWidth: "2px",
            borderColor: message ? "#d32f2f" : "#3498db",
          },
        },
        ".ql-toolbar.ql-snow": {
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          borderColor: message ? "#d32f2f" : "#ccc",
        },
        ".ql-container.ql-snow": {
          height: `${height}px`,
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          borderColor: message ? "#d32f2f" : "#ccc",
        },
      }}
    >
      {label ? (
        <FormLabel fontSize="14" fontWeight={700}>
          {label}
        </FormLabel>
      ) : null}
      <Box
        sx={{
          pointerEvents: disabled ? "none" : "unset",
          color: disabled ? "rgba(0, 0, 0, 0.38)" : "black",
          flex: 1,
        }}
      >
        <ReactQuill
          className="default-font-size"
          modules={modules}
          formats={formats}
          theme="snow"
          value={value}
          onChange={handleEditorChange}
          onKeyDown={(e: any) => disabled && e.preventDefault()}
          placeholder={placeholder}
          style={{ height: `${height}px` }}
        />
        {message && (
          <FormErrorMessage
            ml={2}
            mt={0.5}
            sx={{ fontSize: "13px" }}
            color="error"
          >
            {message}
          </FormErrorMessage>
        )}
      </Box>
    </Box>
  );
};

export default TextEditorNews;
