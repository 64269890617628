import React from "react";
// import _ from "lodash";
import {
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Box,
  Switch,
} from "@chakra-ui/react";
import { Table, Pagination } from "rsuite";

import { type IPaginate, type TActions } from "@/Types/Common.types";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";

import { NoDataFound } from "@/Components/Common";
import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;
interface IProps {
  payload: any[];
  paginate: IPaginate;
  onPageChange?: (page: number) => void;
  onRowAction: (action: TActions, item: any) => void;
  onSwitchStatus: (value: boolean, item: any) => void;
  onChangeLimit?: (limint: number) => void;
  actions?: TActions[];
  showTotal?: boolean;
  isLoading?: boolean;
}

const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const NewsDataTable: React.FC<IProps> = ({
  payload,
  paginate,
  onPageChange,
  onRowAction,
  onSwitchStatus,
  isLoading,
  onChangeLimit,
}) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;
  const t = useTranslation();

  const locale = {
    limit: `{0} ${t("label.page")}`,
    total: `${t("label.totalRows")}: {0}`,
    skip: `${t("label.goTo")} {0}`,
  };

  return (
    <Box>
      <Table
        data={payload}
        autoHeight
        affixHeader
        affixHorizontalScrollbar
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
      >
        <Column flexGrow={1} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.title")}
          </HeaderCell>
          <Cell dataKey="title" />
        </Column>
        <Column flexGrow={1} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.content")}
          </HeaderCell>
          <Cell dataKey="content">
            {(rowData) => (rowData.content ? rowData?.content : "--")}
          </Cell>
        </Column>
        <Column align="center" verticalAlign="middle" width={100} resizable>
          <HeaderCell style={HEADER_STYLE}>{t("table.status")}</HeaderCell>
          <Cell>
            {(rowData) => {
              return (
                <Box
                  sx={{
                    "& .css-1rhx8gq[data-checked]": {
                      background: "#bb7154",
                    },
                  }}
                >
                  <Switch
                    isChecked={rowData?.status === "active"}
                    size={"sm"}
                    colorScheme="whatsapp"
                    id={`news-${rowData?.id}`}
                    onChange={(e: any) =>
                      onSwitchStatus(e.target.checked, rowData.id)
                    }
                    //sx={{ background: "#a6b7aa" }}
                  />
                </Box>
              );
            }}
          </Cell>
        </Column>
        <Column align="center" resizable fixed="right">
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.actions")}
          </HeaderCell>
          <Cell style={{ padding: "5px" }}>
            {(rowData) => {
              return (
                <Stack direction="row" justifyContent="center">
                  <Tooltip label={t("tooltip.edit")}>
                    <IconButton
                      sx={{
                        bg: "transparent",
                      }}
                      size="md"
                      isRound
                      icon={<Icon as={PencilSquareIcon} />}
                      aria-label={""}
                      onClick={() => onRowAction("edit", rowData)}
                    />
                  </Tooltip>
                  <Tooltip label={t("tooltip.delete")}>
                    <IconButton
                      sx={{
                        bg: "transparent",
                      }}
                      size="md"
                      isRound
                      icon={<Icon as={TrashIcon} sx={{ color: "#bb7154" }} />}
                      aria-label={""}
                      color="error"
                      onClick={() => onRowAction("delete", rowData)}
                    />
                  </Tooltip>
                </Stack>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <Box
        sx={{
          p: "20px",
          bg: "white",
          "& .rs-pagination-btn-active": {
            border: "1px solid #bb7154",
            color: "#bb7154",
          },
          "& .rs-pagination-btn-active:hover": {
            color: "#bb7154",
            border: "1px solid #bb7154",
            boxShadow: "0 0 0 1px #bb7154",
          },
          "& .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value":
            {
              color: "#bb7154!important",
            },
          "& .rs-picker-toggle:hover": {
            borderColor: "#bb7154!important",
          },
        }}
      >
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          layout={
            !isMobile ? ["total", "-", "limit", "pager", "skip"] : undefined
          }
          total={paginate.totalItems}
          limitOptions={[10, 30, 50]}
          limit={paginate.itemsPerPage}
          activePage={paginate.currentPage}
          onChangePage={onPageChange}
          onChangeLimit={onChangeLimit}
          style={{ color: "#bb7154" }}
          locale={locale}
        />
      </Box>
    </Box>
  );
};

export default NewsDataTable;
