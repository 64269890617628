import { useEffect, useRef } from "react";

import {
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField, Button } from "@/Components/Common";
import TextEditorNews from "@/Components/Common/TextEditorNews";
import _ from "lodash";
import NewsActions from "@/Actions/News.action";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const { createNews, fetchNews } = NewsActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const DEFAULT_FORM_DATA = {
  title: "",
  content: "",
};

const CreateNewsDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const isCreateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.NEWS, "isCreateSuccess")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.NEWS, "isActionLoading")
  );

  const schema = yup
    .object()
    .shape({
      title: yup.string().required(t("message.titleIsRequired")),
      content: yup.string().required(t("message.contentIsRequired")),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const onSubmit = (data: any) => {
    dispatch(createNews(data));
    onClose(); // Đóng popup sau khi submit
    dispatch(fetchNews()); // Tải lại danh sách tin tức
  };

  useEffect(() => {
    if (!open) {
      onClose();
      reset();
    }
  }, [open, isCreateSuccess]);

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      finalFocusRef={cancelRef}
      onClose={onClose}
      isCentered
      size="2xl" // Expand width by 50%
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          sx={{
            maxH: "full",
            width: "80vw", // Ensure expanded width
            maxWidth: "1200px", // Set maximum width
          }}
        >
          <AlertDialogHeader
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            sx={{
              position: "sticky",
              zIndex: "sticky",
              top: 0,
              backgroundColor: "#fff",
              boxShadow: "0 1px 2px -1px gray",
              borderRadius: "15px 15px 0 0",
            }}
          >
            {t("title.createNews")}
          </AlertDialogHeader>
          <AlertDialogBody
            sx={{
              overflowY: "auto",
            }}
          >
            <Box mt={2}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    label={t("label.title")}
                    placeholder={t("label.title")}
                    size="sm"
                    isRequired
                    isError={!_.isEmpty(errors.title?.message)}
                    errorMessage={errors?.title?.message}
                  />
                )}
              />
            </Box>
            <Box mt={2}>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <TextEditorNews
                    label={t("label.content")}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    placeholder={t("label.content")}
                    height={400} // Provide more space for rich content
                  />
                )}
              />
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter
            sx={{
              position: "sticky",
              bottom: 0,
              background: "#fff",
              boxShadow: "0px 0px 2px gray",
              borderRadius: "0 0 15px 15px",
            }}
          >
            <Button
              size={"sm"}
              onClick={() => onClose()}
              isLoading={isActionLoading}
              w={75}
            >
              {t("button.cancel")}
            </Button>
            <Button
              ml={3}
              colorScheme="twitter"
              onClick={handleSubmit(onSubmit)}
              size={"sm"}
              isLoading={isActionLoading}
              w={75}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.create")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CreateNewsDialog;
