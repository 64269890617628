import _ from "lodash";
import jsSHA from "jssha";
import jwtDecode from "jwt-decode";
import { ApisauceInstance } from "apisauce";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { cookie_keys } from "@/Constants";
import Cookies from "universal-cookie";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const cookies = new Cookies();
import {
  S3,
  GetObjectCommand,
  GetObjectCommandOutput,
} from "@aws-sdk/client-s3";

import { history } from "@/Store";
import { Toast } from "@/Widgets";

import { ENUMS, PERMISSIONS, Routers, ACCESS_MODULES, APIs } from "@/Constants";

import { IRoleStructure } from "@/Interfaces/Role.interface";
import { NOTIFICATION_TYPE } from "@/Constants/Enums.contant";

import {
  saveToken,
  saveRefreshToken,
  getSavedToken,
  getSavedUserData,
  getSavedRefreshToken,
} from "./Cookie.utils";
import { updateToken } from "@/Configs/socket.config";

dayjs.extend(relativeTime);

const s3Client = new S3({
  credentials: {
    accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
    secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  },
  region: import.meta.env.VITE_AWS_REGION,
});

const isJsonString = (str: string) => {
  try {
    if (/^\{.*\}$/.test(str)) {
      JSON.parse(str);
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

// Redirect screen
const detectLocationBeforeRedirect = (location: string) => {
  // const userRole: ROLE.ADMIN | ROLE.EVENT_ADMIN | ROLE.USER = getUserRole();
  // if (userRole) {
  //   const allowLocation = LOCATIONS[userRole];
  //   if (!_.includes(allowLocation, location)) {
  //     Alert({
  //       type: 'ERROR',
  //       message:
  //         "Your account doesn't have permission to view or manage this page",
  //     });
  //     return { isValid: false, redirectLocation: REDIRECT[userRole] };
  //   }
  // }
  return { isValid: true, redirectLocation: location };
};

const redirect = (location: string, state?: any) => {
  return history.push(location, state);
};

const getAccountRole = () => {
  const userData = getSavedUserData();
  if (userData) {
    const result = _.get(userData, "role.name");
    return result;
  }
  return false;
};

const getAccountId = () => {
  const userData = getSavedUserData();
  if (userData) {
    const result = userData.id;
    return result;
  }
  return false;
};

const checkRouterAccess = (
  router: string,
  userRoles: string[],
  isRedirect?: boolean
) => {
  const permissions: any = PERMISSIONS[router as keyof typeof PERMISSIONS];

  if (
    !permissions ||
    !userRoles.some((role) => permissions.allowed.includes(role))
  ) {
    return isRedirect ? redirect(Routers.DASHBOARD) : false;
  }

  return true;
};

const hasPermission = (
  roles: IRoleStructure[],
  module: any,
  action: string
) => {
  const modulePermissions = _.get(ACCESS_MODULES, [module, action], []);
  if (!modulePermissions || !Array.isArray(roles)) {
    return false;
  }
  return (
    _.intersection(
      roles.map((role) => role.roleCode),
      modulePermissions
    ).length > 0
  );
};

const getDayOfWeek = (date: Date | string, type?: "long" | "short") =>
  new Date(date).toLocaleString(
    getSavedLanguage() === "en" ? "en-us" : "vi-Vi",
    { weekday: type || "long" }
  );

const handleCheckCanDragAndModifyState = (
  userRoles: string[],
  currentState: string,
  nextState: string
): boolean => {
  // Check the state and permissions of the user
  if (
    nextState === ENUMS.PROJECT_STATUS.WAITING_PROCESS ||
    nextState === ENUMS.PROJECT_STATUS.HISTORY ||
    currentState === ENUMS.PROJECT_STATUS.HISTORY ||
    currentState === ENUMS.PROJECT_STATUS.WAITING_PROCESS
  ) {
    // For WAITING_PROCESS and HISTORY, only admin and manager have the right to transition
    return (
      userRoles.includes(ENUMS.ROLES.ADMIN) ||
      userRoles.includes(ENUMS.ROLES.MANAGER) ||
      userRoles.includes(ENUMS.ROLES.HUMAN_RESOURCES)
    );
  }
  // For other states, allow all users
  return true;
};

const removeEmptyFields = (obj: object) => {
  return _.pickBy(obj, (value) => {
    if (_.isArray(value)) return !_.isEmpty(value);
    return value !== "" && value !== null && value !== undefined;
  });
};

// Function to map file extensions to MIME types
const getFileTypeFromExtension = (extension: any) => {
  // Define a mapping of file extensions to MIME types
  const extensionMap = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    bmp: "image/bmp",
    tiff: "image/tiff",
    svg: "image/svg+xml",
    webp: "image/webp",
    ico: "image/x-icon",
    mp3: "audio/mpeg",
    wav: "audio/wav",
    ogg: "audio/ogg",
    flac: "audio/flac",
    m4a: "audio/mp4",
    mp4: "video/mp4",
    avi: "video/x-msvideo",
    mov: "video/quicktime",
    mkv: "video/x-matroska",
    webm: "video/webm",
    wmv: "video/x-ms-wmv",
    pdf: "application/pdf",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    txt: "text/plain",
    rtf: "application/rtf",
    csv: "text/csv",
    html: "text/html",
    css: "text/css",
    js: "application/javascript",
    json: "application/json",
    xml: "application/xml",
    zip: "application/zip",
    rar: "application/x-rar-compressed",
    tar: "application/x-tar",
    gz: "application/gzip",
    exe: "application/vnd.microsoft.portable-executable",
    dll: "application/vnd.microsoft.portable-executable",
    // Add other file types as needed
  };

  // Convert the file extension to lowercase and check if it's in the map
  const lowercaseExtension = extension.toLowerCase();
  return (
    extensionMap[lowercaseExtension as keyof typeof extensionMap] ||
    "application/octet-stream"
  );
};

const getFileExtension = (filename: string) => {
  return filename?.split(".").pop();
};

const getMimeTypeFromFile = (filename: string) => {
  const fileExtension = getFileExtension(filename);
  if (fileExtension) {
    return getFileTypeFromExtension(fileExtension);
  }
  return "application/octet-stream"; // Default MIME type for unknown files
};

const getFileFromURL = async (url: string, filename: string) => {
  const resolveUrl = url?.replace(/\\/g, "/")?.replace("src/storage/file", "");
  const blob = await fetch(resolveUrl, {}).then((r) => r.blob());
  const file = new File([blob], filename, {
    type: getMimeTypeFromFile(filename),
  });
  return file;
};

const asStream = (response: GetObjectCommandOutput) => {
  return response.Body as ReadableStream;
};

const asBlob = async (response: GetObjectCommandOutput) => {
  return new Response(asStream(response)).blob();
};

const getAWSFileAsBlob = (url: string, filename: string, type?: string) => {
  return new Promise(async (resolve) => {
    const key: string = _.last(url.split("/")) || "";
    try {
      const command = new GetObjectCommand({
        Bucket: import.meta.env.VITE_AWS_PUBLIC_BUCKET_NAME,
        Key: key,
        ResponseContentType: "application/json",
      });

      const response = await s3Client.send(command);
      if (response.Body) {
        const blobFile = await asBlob(response);
        let file;
        if (type === "pdf")
          file = new File([blobFile], filename, {
            type: "application/pdf",
          });
        else file = new File([blobFile], filename);
        resolve(file);
      }
      resolve(null);
    } catch (err) {
      resolve(null);
    }
  });
};

const calculateTimeAgo = (timestamp: string) => {
  const currentTime = dayjs();
  const targetTime = dayjs(timestamp);
  const language = getSavedLanguage();

  const diffInSeconds = currentTime.diff(targetTime, "second");
  const diffInMinutes = currentTime.diff(targetTime, "minute");
  const diffInHours = currentTime.diff(targetTime, "hour");
  const diffInDays = currentTime.diff(targetTime, "day");
  const diffInMonths = currentTime.diff(targetTime, "month");
  const diffInYears = currentTime.diff(targetTime, "year");

  let timeAgo;

  switch (true) {
    case diffInSeconds < 60:
      timeAgo = language === "en" ? "just now" : "ngay bây giờ";
      break;
    case diffInMinutes < 60:
      timeAgo = `${diffInMinutes} ${
        language === "en" ? "minutes ago" : "phút trước"
      } `;
      break;
    case diffInHours < 24:
      timeAgo = `${diffInHours} ${
        language === "en" ? "hours ago" : "giờ trước"
      } `;
      break;
    case diffInDays < 30:
      timeAgo = `${diffInDays} ${
        language === "en" ? "days ago" : "ngày trước"
      }  `;
      break;
    case diffInMonths < 12:
      timeAgo = `${diffInMonths} ${
        language === "en ago" ? "months" : "tháng trước"
      }  `;
      break;
    default:
      timeAgo = `${diffInYears} ${
        language === "en" ? "years ago" : "năm trước"
      }  `;
      break;
  }

  return timeAgo;
};

// Sleep for delay
const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

const convertTimeInt = (time: any) => {
  const [hours, minutes, seconds] = time.split(":");
  return dayjs()
    .set("hour", Number(hours))
    .set("minute", Number(minutes))
    .set("second", Number(seconds ?? 0))
    .unix();
};

const calculateWorkingTime = (startTime: string, endTime: string) => {
  if (startTime && endTime) {
    let workingTimeTemp = null;
    const convertStartTime = convertTimeInt(startTime);
    const convertEndTime = convertTimeInt(endTime);

    if (convertEndTime < convertStartTime)
      workingTimeTemp = convertEndTime + 24 * 60 * 60 - convertStartTime;
    else workingTimeTemp = convertEndTime - convertStartTime;
    const workingTimeConvert = Math.round((workingTimeTemp / 3600) * 100) / 100;
    return workingTimeConvert;
  }
  return 0;
};

const findAddedAndRemovedItems = (
  originalArray: string[],
  updatedArray: string[]
) => {
  const removedItems = _.difference(originalArray, updatedArray);
  const addedItems = _.difference(updatedArray, originalArray);

  return {
    removedItems,
    addedItems,
  };
};

const parseEstimated = (estimatedString: string) => {
  if (estimatedString) {
    const match = estimatedString.match(/(\d+) days, (\d+) hours/);

    if (match) {
      const days = parseInt(match[1], 10);
      const hours = parseInt(match[2], 10);
      return { days, hours };
    }
  }
  return { days: 0, hours: 0 };
};

const formatHourMinute = (timeString: string | undefined) => {
  if (timeString) {
    const [hours, minutes] = timeString.split(":");
    if (hours && minutes && hours.length === 2 && minutes.length === 2) {
      return `${hours}:${minutes}`;
    }
  }
  return "00:00";
};

const formatTime = (timeString: string | undefined) => {
  if (timeString) {
    const [hours, minutes] = timeString?.split(":");
    if (hours && minutes && hours.length === 2 && minutes.length === 2) {
      return { hours, minutes };
    }
  }
  return { hours: "00", minutes: "00" };
};

const getHourAndMinute = (timeString: string) => {
  if (!timeString) {
    return { hour: 0, minute: 0 };
  }
  const [hour, minute] = timeString?.split(":")?.map(Number);
  return { hour, minute };
};

const extractTimeValues = (startTime: string, endTime: string) => {
  const start = formatTime(startTime);
  const end = formatTime(endTime);

  return {
    startHours: start.hours,
    startMinutes: start.minutes,
    endHours: end.hours,
    endMinutes: end.minutes,
  };
};

const getFileTypeMessage = (attachment: any) => {
  const imageTypes = ["image", "jpg", "jpeg", "png", "gif"];
  const videoTypes = ["video"];
  const wordTypes = ["doc", "docx"];
  const excelTypes = ["xls", "xlsx"];
  const powerpointTypes = ["ppt", "pptx"];
  const pdfTypes = ["pdf"];
  const otherFileTypes = ["file"];

  let result = { text: "", icon: "" };

  switch (true) {
    case _.includes(imageTypes, attachment.type):
      result = { text: "Sent an image", icon: "image-icon" };
      break;
    case _.includes(videoTypes, attachment.type):
      result = { text: "Sent a video", icon: "video-icon" };
      break;
    case _.includes(wordTypes, attachment.type):
      result = { text: "Sent a Word document", icon: "word-icon" };
      break;
    case _.includes(excelTypes, attachment.type):
      result = { text: "Sent an Excel document", icon: "excel-icon" };
      break;
    case _.includes(powerpointTypes, attachment.type):
      result = {
        text: "Sent a PowerPoint presentation",
        icon: "powerpoint-icon",
      };
      break;
    case _.includes(pdfTypes, attachment.type):
      result = { text: "Sent a PDF document", icon: "pdf-icon" };
      break;
    case _.includes(otherFileTypes, attachment.type):
      result = { text: "Sent a file", icon: "file-icon" };
      break;
    default:
      result = { text: "Unknown attachment type", icon: "unknown-icon" };
      break;
  }
  return result;
};

const resolveTimeAgo = (data: string | Date) => {
  const date = dayjs(data);
  const now = dayjs();
  const diffInMinutes = now.diff(date, "minute");
  const diffInDays = now.diff(date, "day");
  const diffInMonths = now.diff(date, "month");
  const diffInYears = now.diff(date, "year");

  const minuteThreshold = 60;

  if (diffInYears > 0) {
    return `${diffInYears}y`;
  }
  if (diffInMonths > 0) {
    return `${diffInMonths}m`;
  }
  if (diffInDays > 0) {
    return `${diffInDays}d`;
  }
  if (diffInMinutes >= minuteThreshold) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours}h`;
  }

  if (diffInMinutes > 0) {
    return `${diffInMinutes}m`; // Show minutes if it's less than an hour
  }

  return "now";
};

const sortByProperty = (array: any[], property: string, descending = false) => {
  const sortOrder = descending ? 1 : -1;

  return [...array].sort((a, b) => {
    const valueA = new Date(a[property]).getTime();
    const valueB = new Date(b[property]).getTime();

    return sortOrder * (valueA - valueB);
  });
};

const mapNotificationToPath = (type: string) => {
  switch (type) {
    case NOTIFICATION_TYPE.USER:
      return Routers.USER;
    case NOTIFICATION_TYPE.DAY_OFF_REQUEST:
      return Routers.DAY_OFF_REQUEST;
    case NOTIFICATION_TYPE.HOLIDAY:
      return Routers.HOLIDAYS;
    case NOTIFICATION_TYPE.INTERNAL_IP_ADDRESS:
      return Routers.INTERNAL_IP;
    case NOTIFICATION_TYPE.KANBAN_BOARD:
      return Routers.BOARD;
    case NOTIFICATION_TYPE.LEAVE_DAY:
      return Routers.LEAVE_DAY;
    case NOTIFICATION_TYPE.LOG_TIME:
      return Routers.BOARD_DETAIL;
    case NOTIFICATION_TYPE.OVERTIME:
      return Routers.OVER_TIME;
    case NOTIFICATION_TYPE.PERFORMANCE_EVALUATION:
      return Routers.PERFORMANCE;
    case NOTIFICATION_TYPE.PROJECT:
      return Routers.PROJECT_DETAILS;
    case NOTIFICATION_TYPE.TASK:
      return Routers.BOARD_DETAIL;
    case NOTIFICATION_TYPE.TASK_COMMENT:
      return Routers.BOARD_DETAIL;
    case NOTIFICATION_TYPE.TIMEKEEPING:
      return Routers.TIME_KEEPPING;
    case NOTIFICATION_TYPE.TIMESHEET:
      return Routers.TIME_SHEET;
    case NOTIFICATION_TYPE.REQUEST_UPDATE_TIMECARD:
      return Routers.REQUEST_UPDATE_TIME_CARD;
    case NOTIFICATION_TYPE.CHAT:
      return Routers.CHAT;
    case NOTIFICATION_TYPE.LOG_TIME:
      return Routers.MY_TIME;
    case NOTIFICATION_TYPE.NEWS:
      return Routers.NEWS;
    default:
      return Routers.ERROR_404;
  }
};

const convertImageToBlob = async (imageUrl: string): Promise<Blob | null> => {
  try {
    const response = await fetch(imageUrl);
    const blobData = await response.blob();
    return blobData;
  } catch (error) {
    await Toast({
      title: `Error copying image to blob:, ${error}`,
      status: "error",
    });
    return null;
  }
};

const copyImageToClipboard = async (imageUrl: string) => {
  try {
    const blobData = await convertImageToBlob(imageUrl);

    if (blobData) {
      const arrayBuffer = await blobData.arrayBuffer();
      const convertBlob = new Blob([arrayBuffer], { type: "image/png" });
      const clipboardItemInput = new ClipboardItem({
        "image/png": convertBlob,
      });
      if (navigator.clipboard && navigator.clipboard.write) {
        await navigator.clipboard.write([clipboardItemInput]);
      }
    }
  } catch (e) {
    await Toast({
      title: `Error copying image to Clipboard:, ${e}`,
      status: "error",
    });
  }
};

const resolveMessages = (payload: any) => {
  const userLogged = getSavedUserData();
  return _.map(payload, (message) => {
    return {
      id: message?.id,
      timestamp: message?.createdAt,
      isSender: message?.userCreated?.id === userLogged?.id,
      sender: !_.isEmpty(message?.userCreated)
        ? {
            id: message?.userCreated?.id,
            name: message?.userCreated?.userData?.fullName,
            avatar: message?.userCreated?.userData?.avatar?.path,
          }
        : null,
      content: message?.message,
      fileAttachments: message?.fileAttachment,
      recalled: message?.recalled,
    };
  });
};

const getDaysOfWeek = (date: Date, dateFormat: string) => {
  const daysOfWeek = [];
  const firstDayOfWeek = new Date(date);
  firstDayOfWeek.setDate(
    date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)
  );

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(firstDayOfWeek);
    currentDate.setDate(firstDayOfWeek.getDate() + i);
    daysOfWeek.push(dayjs(currentDate).format(dateFormat));
  }

  return daysOfWeek;
};

const getNextOrPrevChat = (chats: any[], currentIndex: number) => {
  let nextChat;
  let prevChat;
  if (currentIndex === 0) {
    nextChat = chats[1];
  } else if (currentIndex === chats.length - 1) {
    prevChat = chats[currentIndex - 1];
  } else {
    nextChat = chats[currentIndex + 1];
    prevChat = chats[currentIndex - 1];
  }

  return {
    nextChat,
    prevChat,
  };
};

const getRandomColor = () => {
  const colors = [
    "#5C6E6C", // balsamGreen
    "#A6B7AA", // aquatone
    "#D2A96A", // artermis
    "#D39D87", // dustyCoral
    "#BB7154", // warmCopper
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

const getMaxHoursWorktime = (date: Date) => {
  const currentDate = dayjs(date);
  const currentDay = currentDate.day();
  let maxHours;

  switch (currentDay) {
    case 0: // Sunday
      maxHours = 0; // No work on Sunday
      break;
    case 1: // Monday
      maxHours = 48;
      break;
    case 2: // Tuesday
      maxHours = 40;
      break;
    case 3: // Wednesday
      maxHours = 32;
      break;
    case 4: // Thursday
      maxHours = 24;
      break;
    case 5: // Friday
      maxHours = 16;
      break;
    case 6: // Saturday
      maxHours = 16;
      break;
    default:
      maxHours = 0; // Default to no work
  }

  return maxHours;
};

const isValidTimeRange = (
  start: string | Date,
  end: string | Date,
  workingTime: {
    morningStart: number;
    morningEnd: number;
    afternoonStart: number;
    afternoonEnd: number;
  }
) => {
  const startTime = dayjs(start);
  const endTime = dayjs(end);
  const { morningStart, morningEnd, afternoonStart, afternoonEnd } =
    workingTime;

  const { hour: morningStartHour, minute: morningStartMinute } =
    splitHourMinute(morningStart);
  const { hour: morningEndHour, minute: morningEndMinute } =
    splitHourMinute(morningEnd);
  const { hour: afternoonStartHour, minute: afternoonStartMinute } =
    splitHourMinute(afternoonStart);
  const { hour: afternoonEndHour, minute: afternoonEndMinute } =
    splitHourMinute(afternoonEnd);

  const isWithinMorning = (time: dayjs.Dayjs) => {
    const hour = time?.hour();
    const minute = time?.minute();
    const timeInMinutes = hour * 60 + minute;
    const morningStartTimeInMinutes =
      morningStartHour * 60 + morningStartMinute;
    const morningEndTimeInMinutes = morningEndHour * 60 + morningEndMinute;
    return (
      timeInMinutes >= morningStartTimeInMinutes &&
      timeInMinutes <= morningEndTimeInMinutes
    );
  };

  const isWithinAfternoon = (time: dayjs.Dayjs) => {
    const hour = time?.hour();
    const minute = time?.minute();
    const timeInMinutes = hour * 60 + minute;
    const startAfternoonInMinutes =
      afternoonStartHour * 60 + afternoonStartMinute;
    const endAfternoonInMinutes = afternoonEndHour * 60 + afternoonEndMinute;
    return (
      timeInMinutes >= startAfternoonInMinutes &&
      timeInMinutes <= endAfternoonInMinutes
    );
  };

  return (
    (isWithinMorning(startTime) || isWithinAfternoon(startTime)) &&
    (isWithinMorning(endTime) || isWithinAfternoon(endTime))
  );
};

const splitHourMinute = (decimalTime: number) => {
  const hour = Math.floor(decimalTime);
  const minute = Math.floor((decimalTime - hour) * 60);
  return { hour, minute };
};

const calculateWorkingTimeForSameDay = (
  date: dayjs.Dayjs,
  endDate: dayjs.Dayjs
) => {
  const getStartHour = date.hour();
  const getStartMinutes = Number((date.minute() / 60).toFixed(1));
  const getEndHour = endDate.hour();
  const getEndMinutes = Number((endDate.minute() / 60).toFixed(1));
  const startTime = getStartHour + getStartMinutes;
  const endTime = getEndHour + getEndMinutes;
  if ((startTime <= 12 && endTime <= 12) || (startTime > 12 && endTime > 12))
    return endTime - startTime;
  return endTime - startTime - 1.5;
};

const calculateHourDifference = (
  startDate: string | Date,
  endDate: string | Date,
  workingTime: {
    morningStart: number;
    morningEnd: number;
    afternoonStart: number;
    afternoonEnd: number;
  },
  holidaysList?: any
) => {
  const start = dayjs(_.toString(startDate));
  const end = dayjs(_.toString(endDate));

  const totalWortime =
    workingTime.morningEnd -
    workingTime.morningStart +
    workingTime.afternoonEnd -
    workingTime.afternoonStart;

  const startDateNew = new Date(dayjs(startDate).format("YYYY-MM-DD"));
  const endDateNew = new Date(dayjs(endDate).format("YYYY-MM-DD"));

  const isHoliday = (date: any) => {
    return (
      holidaysList &&
      holidaysList.some(
        (holiday: any) => new Date(holiday.day).getTime() === date.getTime()
      )
    );
  };

  const countHolidays = (startDate: any, endDate: any) => {
    let count = 0;
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      if (isHoliday(currentDate)) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return count;
  };

  const numHolidays = countHolidays(startDateNew, endDateNew);

  const { morningStart, afternoonEnd } = workingTime;
  const { hour: morningStartHour, minute: morningStartMinute } =
    splitHourMinute(morningStart);
  const { hour: afternoonEndHour, minute: afternoonEndMinute } =
    splitHourMinute(afternoonEnd);

  let totalWorkingHours = 0;
  const getDiff = end.diff(start, "day") + 1;
  let sundays = 0;
  _.forEach(_.range(getDiff), (day) => {
    if (day && !start.add(day, "day").day()) sundays += 1;
  });
  // handle same day
  if (start.isSame(end, "day")) {
    const startHour = start.get("hour");
    const startMinute = start.get("minute");
    const lastWorkStart = _.clone(end)
      .set("hour", startHour)
      .set("minute", startMinute)
      .set("second", 0);
    const lastWorkEnd = end.clone();
    lastWorkEnd.set("second", 0);
    const totalHours = calculateWorkingTimeForSameDay(
      lastWorkStart,
      lastWorkEnd
    );
    totalWorkingHours += totalHours;
  } else {
    // const {hour,minute} = splitHourMinute(workingTime.afternoonEnd);
    const lastWorkFirstDate = _.clone(start)
      .set("hour", afternoonEndHour)
      .set("minute", afternoonEndMinute)
      .set("second", 0);
    const startWorksecondDate = _.clone(end)
      .set("hour", morningStartHour)
      .set("minute", morningStartMinute)
      .set("second", 0);
    const totalHoursInFirstDate = calculateWorkingTimeForSameDay(
      start,
      lastWorkFirstDate
    );
    const totalHoursInsecondDate = calculateWorkingTimeForSameDay(
      startWorksecondDate,
      end
    );
    totalWorkingHours =
      getDiff === 2
        ? totalHoursInFirstDate + totalHoursInsecondDate
        : totalWortime * (getDiff - 2) +
          totalHoursInFirstDate +
          totalHoursInsecondDate;

    if (sundays || holidaysList)
      totalWorkingHours =
        totalWorkingHours - totalWortime * sundays - numHolidays * totalWortime;
  }

  return totalWorkingHours;
};

const subtractTime = (startTime: any, minutesToSubtract: any) => {
  const [startHours, startMinutes, startSeconds] =
    startTime && startTime?.split(":").map(Number);

  const totalStartMinutes = startHours * 60 + startMinutes;

  const newTotalMinutes = totalStartMinutes - minutesToSubtract;

  const newHours = Math.floor(newTotalMinutes / 60);
  const newMinutes = newTotalMinutes % 60;
  const newSeconds = startSeconds;

  const formattedHours = newHours.toString().padStart(2, "0");
  const formattedMinutes = newMinutes.toString().padStart(2, "0");
  const formattedSeconds = newSeconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const convertToTime = (decimalTime: any) => {
  const hours = Math.floor(decimalTime);
  const decimalMinutes = (decimalTime - hours) * 60;
  const minutes = Math.round(decimalMinutes);

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:00`; // Format giờ và phút
};

//const convertToTime = (time: any) => {
//  const [hours, minutes] = time.split(".").map(Number);
//  const formattedHours = hours.toString().padStart(2, "0");
//  const formattedMinutes = (minutes * 60).toString().padStart(2, "0");
//  return `${formattedHours}:${formattedMinutes}:00`;
//};

const handleCheckPermissions = (
  currentUserRoles: string[],
  targetUserRoles: string[]
): boolean => {
  const isCurrentArtist = currentUserRoles.includes(ENUMS.ROLES.ARTIST);
  const currentUserLeader = currentUserRoles?.includes(ENUMS.ROLES.LEADER);

  const allowed = true;
  const isTargetUserArtist =
    targetUserRoles.includes(ENUMS.ROLES.ARTIST) &&
    (!targetUserRoles.includes(ENUMS.ROLES.MANAGER) ||
      !targetUserRoles.includes(ENUMS.ROLES.LEADER));
  if (isCurrentArtist || (currentUserLeader && !isTargetUserArtist))
    return false;
  return allowed;
};

const handleCheckLogTimePermissions = (
  currentUserRoles: string[],
  targetUserRoles: string[],
  type?: ENUMS.PROJECT_TYPE
): boolean => {
  const isCurrentAdmin = currentUserRoles.includes(ENUMS.ROLES.ADMIN);
  const isCurrentManager = currentUserRoles.includes(ENUMS.ROLES.MANAGER);
  const isCurrentHR = currentUserRoles.includes(ENUMS.ROLES.HUMAN_RESOURCES);
  const isCurrentLeader = currentUserRoles.includes(ENUMS.ROLES.LEADER);
  const isCurrentMkt = currentUserRoles.includes(ENUMS.ROLES.MARKETING);

  if (type === ENUMS.PROJECT_TYPE.EXTERIOR) {
    if (isCurrentAdmin) return true;
    if (isCurrentHR || isCurrentManager) {
      const targetIsMnOrHr = !_.some(targetUserRoles, (role) => {
        return _.includes(
          [ENUMS.ROLES.ADMIN, ENUMS.ROLES.MANAGER, ENUMS.ROLES.HUMAN_RESOURCES],
          role
        );
      });

      if (targetIsMnOrHr) {
        return true;
      }
    }

    if (isCurrentLeader || isCurrentMkt) {
      const isTargetArtist =
        _.includes(targetUserRoles, ENUMS.ROLES.ARTIST) &&
        !_.includes(targetUserRoles, ENUMS.ROLES.LEADER) &&
        !_.includes(targetUserRoles, ENUMS.ROLES.MANAGER) &&
        !_.includes(targetUserRoles, ENUMS.ROLES.HUMAN_RESOURCES);
      if (isTargetArtist) return true;
    }
  }
  if (type === ENUMS.PROJECT_TYPE.INTERIOR) {
    if (isCurrentAdmin) return true;
    if (isCurrentManager) {
      const targetIsMn = !_.some(targetUserRoles, (role) => {
        return _.includes([ENUMS.ROLES.ADMIN, ENUMS.ROLES.MANAGER], role);
      });
      if (targetIsMn) {
        return true;
      }
    }

    if (isCurrentLeader) {
      const isTargetArtist =
        _.includes(targetUserRoles, ENUMS.ROLES.ARTIST) &&
        !_.includes(targetUserRoles, ENUMS.ROLES.LEADER) &&
        !_.includes(targetUserRoles, ENUMS.ROLES.MANAGER);
      if (isTargetArtist) return true;
    }
  }
  return false;
};

const generateDefaultTimeline = (numberOfDays: number) => {
  const currentDay = dayjs();
  const weekdays: string[] = [];

  for (let i = -numberOfDays; i < 0; i++) {
    const day = currentDay.add(i, "day");
    weekdays.push(day.format("YYYY-MM-DD"));
  }

  weekdays.push(currentDay.format("YYYY-MM-DD"));

  for (let i = 1; i <= numberOfDays; i++) {
    const day = currentDay.add(i, "day");
    weekdays.push(day.format("YYYY-MM-DD"));
  }

  return weekdays;
};

const generateDefaultWeekDay = () => {
  const currentWeekStart = dayjs().startOf("week").add(1, "day");
  const weekdays: string[] = [];
  for (let i = 0; i < 7; i++) {
    const day = currentWeekStart.add(i, "day");
    weekdays.push(day.format("YYYY-MM-DD"));
  }
  return weekdays;
};

const generateDaysInCurrentMonth = () => {
  const currentMonthStart = dayjs().startOf("month");
  const daysInMonth = currentMonthStart.daysInMonth();
  const daysArray = [];

  for (let i = 0; i < daysInMonth; i++) {
    const day = currentMonthStart.add(i, "day");
    daysArray.push(day.format("YYYY-MM-DD"));
  }
  return daysArray;
};

const getFirstTextContent = (htmlString: string) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  const firstTextNode = tempElement.querySelector("*:not(br)");
  return firstTextNode ? firstTextNode.textContent : "";
};

const generateDeviceFingerprint = () => {
  let fingerprintComponents = [];
  fingerprintComponents.push(window.devicePixelRatio);
  fingerprintComponents.push(window.innerWidth);
  fingerprintComponents.push(navigator.userAgent);
  fingerprintComponents.push(navigator.language);

  fingerprintComponents.push(screen.width + "x" + screen.height);
  fingerprintComponents.push(screen.colorDepth);

  const timezoneOffset = new Date().getTimezoneOffset();
  fingerprintComponents.push(timezoneOffset);

  const plugins = Array.from(navigator.plugins).map((plugin) => [
    plugin.name,
    plugin.description,
  ]);
  fingerprintComponents.push(JSON.stringify(plugins));

  const fontList = Array.from(document.fonts).map((font) => font.family);
  fingerprintComponents.push(JSON.stringify(fontList));

  fingerprintComponents.push(navigator.doNotTrack);

  fingerprintComponents.push(navigator.platform);

  const fingerprint = fingerprintComponents.join("###");

  const hashedFingerprint = sha1(fingerprint);

  return hashedFingerprint;
};

const sha1 = (str: any) => {
  const sha1Hasher = new jsSHA("SHA-1", "TEXT");
  sha1Hasher.update(str);
  return sha1Hasher.getHash("HEX");
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(table|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua))
    return "tablet";
  if (
    /Mobile|IP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  )
    return "mobile";
  return "desktop";
};

// Check life of token
const checkTokenLifeTime = async (api: ApisauceInstance) => {
  const token = getSavedToken();
  const refreshToken = getSavedRefreshToken();
  if (!token && !refreshToken) {
    Toast({
      title: "Please login to continue...!",
      status: "warning",
    });
    return null;
  }
  if (token) {
    const decodedToken: any = jwtDecode(token);
    const dateNow = new Date();
    if (decodedToken.exp < Math.floor(dateNow.getTime() / 1000)) {
      if (refreshToken) {
        const res = await api.post(APIs.AUTHENTICATION.REFRESH_TOKEN, {
          refreshToken,
        });
        const newToken = _.get(res, "data.payload.accessToken.token");
        const newRefreshToken = _.get(
          res,
          "data.payload.accessToken.refreshToken"
        );
        if (newToken && newRefreshToken) {
          saveToken(newToken);
          saveRefreshToken(newRefreshToken);
          updateToken(newToken);
          return newToken;
        }
        Toast({
          title: "Your session has expired! Please login to continue...",
          status: "error",
        });
        return null;
      }
      Toast({
        title: "Your session has expired! Please login to continue...",
        status: "error",
      });
      cookies.remove(cookie_keys.SAVED_SECURE_TOKEN, { path: "/" });
      return false;
    }
  } else {
    return false;
  }
  return token;
};

const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const generateDateRanges = (
  startDate: any,
  endDate: any,
  format = "YYYY-MM-DD"
) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const dateRanges = [];

  let currentDate = start;
  while (currentDate.isBefore(end) || currentDate.isSame(end, "day")) {
    dateRanges.push(currentDate.format(format));
    currentDate = currentDate.add(1, "day");
  }

  return dateRanges;
};

const generateDateRangesByMonths = (
  numberOfMonths: number,
  format = "YYYY-MM-DD"
) => {
  const currentMonth = dayjs().month() + 1;
  let startMonthIndex = 0, endMonthIndex = 0;
  let startYear = dayjs().year(), endYear = dayjs().year();

  const matchingNumberOfMonths = {
    3: [-2, 1],
    6: [-5, 1],
    9: [-8, 1],
    12: [-11, 1],
  };

  if (numberOfMonths === 1) {
      startMonthIndex = currentMonth;
      endMonthIndex = currentMonth;
    } else {
      const matchingValues = matchingNumberOfMonths[numberOfMonths as keyof typeof matchingNumberOfMonths];
      startMonthIndex = currentMonth + matchingValues[0];
      endMonthIndex = currentMonth + matchingValues[1];

      if (startMonthIndex < 1) {
        startMonthIndex += 12;
        startYear -= 1;
      }

      if (endMonthIndex > 12) {
        endMonthIndex -= 12;
        endYear += 1;
      }
    }

  const startDate = dayjs()
    .set("year", startYear)
    .set("month", startMonthIndex - 1)
    .startOf("month");
  const endDate = dayjs()
    .set("year", endYear)
    .set("month", endMonthIndex - 1)
    .endOf("month");

  return generateDateRanges(startDate, endDate, format);
};

const calculateDisplayType = (numberOfDays: number, containerWidth: number) => {
  const labelWidthHidden = 10;
  const labelWidthShort = 15;
  const labelWidthFull = 30;
  const containerWidthPerShortLabel = containerWidth / labelWidthShort;
  const containerWidthPerFullLabel = containerWidth / labelWidthFull;
  const containerWidthPerHidden = containerWidth / labelWidthHidden;

  if (numberOfDays <= containerWidthPerHidden) {
    return "firstDay";
  } else if (numberOfDays <= containerWidthPerShortLabel) {
    return "short";
  } else if (numberOfDays <= containerWidthPerFullLabel) {
    return "full";
  }

  return "hide";
};

const saveLanguage = (lang: string) => {
  localStorage.setItem("language", JSON.stringify(lang));
};

const getSavedLanguage = () => {
  const lang = localStorage.getItem("language");
  if (lang) return JSON.parse(lang);
  return "en";
};

const findDateRange = (data: any, dayToAdd = 7) => {
  const dateMap = {
    minStartDate: "",
    maxEndDate: "",
  };

  const updateMinStartDate = (date: any) => {
    if (date && dayjs(date).isValid()) {
      // Check valid date
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      if (
        !dateMap.minStartDate ||
        dayjs(formattedDate).isBefore(dayjs(dateMap.minStartDate))
      ) {
        dateMap.minStartDate = formattedDate;
      }
    }
  };

  const updateMaxEndDate = (date: any) => {
    if (date && dayjs(date).isValid()) {
      // Check valid date
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      if (
        !dateMap.maxEndDate ||
        dayjs(formattedDate).isAfter(dayjs(dateMap.maxEndDate))
      ) {
        dateMap.maxEndDate = formattedDate;
      }
    }
  };

  if (data?.project?.startDate) {
    updateMinStartDate(data?.project?.startDate);
  }

  if (data?.project?.endDate) {
    updateMaxEndDate(data?.project?.endDate);
  }

  _.forEach(data?.task, (task) => {
    if (task?.timeLineStart && dayjs(task?.timeLineStart).isValid()) {
      updateMinStartDate(task.timeLineStart);
    }

    if (task?.timeLineEnd && dayjs(task?.timeLineEnd).isValid()) {
      updateMaxEndDate(task.timeLineEnd);
    }

    _.forEach(task.logTime, (logTime) => {
      if (logTime?.timeType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME) {
        if (logTime.workTimeStartDate && logTime.workTimeEndDate) {
          updateMinStartDate(logTime.workTimeStartDate);
          updateMaxEndDate(logTime.workTimeEndDate);
        }
      }
    });
  });

  return {
    minStartDate: dateMap.minStartDate,
    maxEndDate: dayjs(dateMap.maxEndDate)
      .add(dayToAdd, "day")
      .format("YYYY-MM-DD"),
  };
};

const calculateDaysAround = (
  dateRanges: string[],
  indexOfCurrentDay: number
) => {
  let daysBeforeCurrentDay = 0;
  let daysAfterCurrentDay = 0;

  if (indexOfCurrentDay !== -1) {
    // calc day before
    for (let i = indexOfCurrentDay - 1; i >= 0; i--) {
      if (!dateRanges.includes(dateRanges[i])) {
        break;
      }
      daysBeforeCurrentDay++;
    }

    // calc day after
    for (let i = indexOfCurrentDay + 1; i < dateRanges.length; i++) {
      if (!dateRanges.includes(dateRanges[i])) {
        break;
      }
      daysAfterCurrentDay++;
    }
  }

  return { daysBeforeCurrentDay, daysAfterCurrentDay };
};

const getMiddleDates = (dateRanges: string[], numDays = 7) => {
  const middleIndex = Math.floor(dateRanges.length / 2);

  const startIndex = middleIndex - Math.floor(numDays / 2);
  const endIndex = middleIndex + Math.ceil(numDays / 2);

  return dateRanges.slice(startIndex, endIndex);
};

const getMiddleValue = (dateRanges: string[]) => {
  const middleIndex = Math.floor(dateRanges.length / 2);
  if (dateRanges.length % 2 === 1) {
    return dateRanges[middleIndex];
  } else {
    return dateRanges[middleIndex + 1];
  }
};

const getFp = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const { ...components } = result.components;
  const visitorId = FingerprintJS.hashComponents(components);
  return visitorId;
};

export {
  redirect,
  detectLocationBeforeRedirect,
  getAccountRole,
  getAccountId,
  checkRouterAccess,
  getDayOfWeek,
  handleCheckCanDragAndModifyState,
  removeEmptyFields,
  getMimeTypeFromFile,
  getFileFromURL,
  getAWSFileAsBlob,
  calculateTimeAgo,
  sleep,
  calculateWorkingTime,
  hasPermission,
  findAddedAndRemovedItems,
  parseEstimated,
  formatHourMinute,
  getHourAndMinute,
  extractTimeValues,
  getFileTypeFromExtension,
  getFileTypeMessage,
  resolveTimeAgo,
  getFileExtension,
  sortByProperty,
  isJsonString,
  mapNotificationToPath,
  copyImageToClipboard,
  resolveMessages,
  getDaysOfWeek,
  getNextOrPrevChat,
  getRandomColor,
  getMaxHoursWorktime,
  isValidTimeRange,
  calculateWorkingTimeForSameDay,
  calculateHourDifference,
  splitHourMinute,
  subtractTime,
  convertToTime,
  formatTime,
  handleCheckPermissions,
  generateDefaultWeekDay,
  generateDaysInCurrentMonth,
  getFirstTextContent,
  handleCheckLogTimePermissions,
  generateDeviceFingerprint,
  getDeviceType,
  checkTokenLifeTime,
  formatBytes,
  generateDateRanges,
  calculateDisplayType,
  saveLanguage,
  getSavedLanguage,
  generateDefaultTimeline,
  findDateRange,
  calculateDaysAround,
  getMiddleDates,
  generateDateRangesByMonths,
  getMiddleValue,
  getFp,
};
